.hero_wrapper {
    margin-top: 56px;
    display: flex;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 78px;
    box-shadow: 0px 40px 60px #23294b33;
}

.text_holder {
    position: relative;
    z-index: 1;
    width: 50%;

    h2 {
        font-weight: 300;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 36px;
        color: #1f285d;
        text-align: left;
    }

    h4 {
        text-align: left;
        font-size: 18px;
        line-height: 24px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-bottom: 16px;
    }

    li {
        opacity: 1;
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
    }
}

.check_icon {
    margin-right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #dde5ff;
    margin-right: 45px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blue_bg,
.title_wrapper {
    padding: 40px;
}

.blue_bg {
    background: #edf2fc;
}

.price_calc {
    margin-top: 38px;
    margin-bottom: -60px;

    @media (max-width: 992px) {
        margin-top: 32px;
        margin-bottom: 0;
    }
}

.video_wrapper {
    width: 50%;

    iframe {
        width: 100%;
        height: 100%;
    }
    & > div {
        height: 100%;
    }
}
.video_link,
.book_now {
    line-height: 40px;
    color: #ffffff;
    padding: 16px 50px;
    border-radius: 26px;
}

.payment_methods {
    margin-top: 30px;
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
    }
    p {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 1400px) {
    .text_holder {
        h2 {
            font-weight: 300;
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 20px;
        }

        h4 {
            text-align: left;
            font-size: 16px;
            line-height: 24px;
        }
        ul {
            margin-bottom: 30px;
        }
        li {
            align-items: center;
            margin-bottom: 15px;
        }
    }

    .check_icon {
        margin-right: 15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #dde5ff;
        margin-right: 30px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blue_bg,
    .title_wrapper {
        padding: 45px 10% 30px;
    }

    .blue_bg {
        background: #edf2fc;
    }

    .video_wrapper {
        width: 50%;

        iframe {
            width: 100%;
            height: 100%;
        }
        & > div {
            height: 100%;
        }
    }
    .video_link,
    .book_now {
        line-height: 40px;
        color: #ffffff;
        padding: 16px 50px;
        border-radius: 26px;
    }

    .payment_methods {
        margin-top: 30px;
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
        p {
            margin-left: 20px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .hero_wrapper {
        .btn_wrapper {
            a {
                margin-bottom: 10px;
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .hero_wrapper {
        flex-direction: column-reverse;
        background: transparent;
        box-shadow: none;
    }
    .video_wrapper,
    .text_holder {
        width: 100%;
    }
    .text_holder {
        h2 {
            font-weight: 500;
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 25px;
        }

        h4 {
            font-weight: 300;
        }

        li {
            font-weight: 300;
        }
    }
    .video_wrapper {
        height: 0;
        padding-bottom: 56%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        iframe {
            position: absolute;
        }
    }

    .blue_bg {
        border-radius: 20px;
    }

    .check_icon {
        margin-right: 15px;
    }
    .video_link,
    .book_now {
        line-height: 40px;
        color: #ffffff;
        padding: 16px 50px;
        border-radius: 26px;
    }

    .payment_methods {
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: center;
        img {
            margin-right: 10px;
        }
        p {
            margin-left: 0;
            width: 100%;
            text-align: center;
            font-weight: 300;
        }
    }

    .btn_wrapper {
        flex-direction: column;
        a,
        button {
            width: 100%;
            text-align: center;
        }
    }
}
