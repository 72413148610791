.date_picker_range {
    width: 100%;
    display: flex;
    top: 0;
    height: 100vh;
    position: fixed;
    align-items: center;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    justify-content: center;
    flex-direction: column;
    transition: 0.5s;
    background: rgba(0, 0, 0, 0.2);

    .close {
        position: absolute;
        z-index: 1;
        top: 20px;
        right: 20px;
        background: #fff;
        padding: 15px;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
            0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    }
    &.show {
        opacity: 1;
        z-index: 22;
        pointer-events: initial;
        div[class*="materialui-daterange-picker-makeStyles-dateRangePickerContainer"] {
            transform: translateY(0);
        }
    }

    .picker_wrapper {
        & > div {
            background: #e5e5e5;
            border-radius: 20px;
        }
    }

    .button_submit {
        border: none;
        margin-top: 20px;
        background: #040484;
        padding: 6px 34px;
        border-radius: 8px;

        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        cursor: pointer;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
            0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    }
}

[class*="materialui-daterange-picker-MuiGrid-root"] > ul {
    display: none;
}
div[class*="materialui-daterange-picker-makeStyles-dateRangeBackdrop"] {
    width: auto;
    height: auto;
}
div[class*="materialui-daterange-picker-MuiMenu-paper"] {
    top: 20% !important;
    max-height: 60vh;
}
div[class*="materialui-daterange-picker-makeStyles-dateRangePickerContainer"] {
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 0.5s;
    transform: translateY(-20px);
    transition: 0.5s;
}
@media only screen and (max-width: 769px) {
    .date_picker_range {
        overflow: scroll;
        justify-content: flex-start;
        padding: 30px 0;
        & > div {
            & > div:nth-of-type(2) {
                & > div {
                    & > div {
                        & > div {
                            & > div:nth-of-type(2) {
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
.DateRangePicker_picker {
    z-index: 32;
}
.range_wrapper_d {
    position: relative;
    &:before,
    &:after {
        margin: 0 !important;
        padding-left: 25px;
    }

    &:before {
        content: attr(labelinput1);
    }

    &:after {
        content: attr(labelinput2);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(12px);
    }
    .DateRangePicker {
        width: 100%;
        display: block;

        ::placeholder {
            opacity: 0;
        }
        .DateRangePickerInput {
            width: 100%;
            display: block;
            border: none;
            background: none;
            display: flex;
        }

        .DateRangePickerInput_calendarIcon {
            order: 2;
        }
        .DateRangePickerInput_arrow {
            order: 4;
        }
        .DateRangePickerInput_arrow,
        .DateRangePickerInput_calendarIcon {
            position: absolute;
            font-size: 20px !important;
            padding: 0 !important;
            z-index: 1;
            top: 15px;
            // pointer-events: none;
        }
        .DateRangePickerInput_arrow {
            right: 20px;
        }
        .DateRangePickerInput_calendarIcon {
            left: 50%;
            margin-left: -50px;
        }
        .DateInput {
            width: 50%;
            background: transparent;
            margin-bottom: 20px;

            &:first-of-type {
                padding-right: 12px;
                order: 1;
            }

            &:last-of-type {
                padding-left: 12px;
                order: 3;
            }
            input {
                margin-bottom: 0;
                padding: 16px 30px;
                height: 52px;
                border: 2px solid #c9d1e1;

                border-radius: 26px;
                background: transparent;
                box-sizing: border-box;
                width: 100%;
            }
        }
    }
    .DateRangePicker_picker__portal {
        z-index: 22;
    }
}
.range_wrapper_m {
    position: relative;
    padding-top: 16px;
    &:before,
    &:after {
        font-size: 16px;
        line-height: 24px;
        display: block;
        transform: translate(20px, 36px) scale(1);
        color: #1f285d;
    }
    input {
        font-size: 16px !important;
        color: #72778d;
    }
    &.shrink {
        &:before,
        &:after {
            transform: translate(20px, -1px) scale(0.75);
        }
    }
    &:before {
        content: attr(labelinput1);
        position: absolute;
        top: -8px;
        left: 0;
    }

    &:after {
        content: attr(labelinput2);
        position: absolute;
        top: 168px;
    }
    .DateRangePicker {
        width: 100%;
        display: block;

        ::placeholder {
            opacity: 0;
        }
        .DateRangePickerInput {
            width: 100%;
            display: block;
            border: none;
            background: none;
        }

        .DateRangePickerInput_arrow,
        .DateRangePickerInput_calendarIcon {
            display: none;
        }

        .DateInput {
            background: transparent;
            padding-right: 10px;
            width: 100%;

            &:first-of-type {
            }

            &:last-of-type {
                position: absolute;
                left: 0;
                top: 176px;
            }
            input {
                margin-bottom: 0;
                padding: 16px 20px;
                height: 48px;
                border: 2px solid #c9d1e1;

                border-radius: 26px;
                background: transparent;
                box-sizing: border-box;
                width: 100%;
            }
        }
    }
    .DateRangePicker_picker__portal {
        z-index: 22;
    }
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff !important;
    color: #cacccd !important;
}
.CalendarDay__selected :active,
.CalendarDay__selected :hover,
.CalendarDay__selected.CalendarDay__blocked_out_of_range:hover,
.CalendarDay__selected,
.CalendarDay__selected.CalendarDay__blocked_out_of_range {
    background: #040484 !important;
    border: 1px double #040484 !important;
}

.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__selected_span {
    background: rgba(4, 4, 132, 0.4) !important;
    border: 1px double rgba(4, 4, 132, 0.4) !important;
    color: #fff !important;
}
.DayPickerKeyboardShortcuts_buttonReset {
    display: none;
}

//inline_calendar

.DayPickerNavigation_button {
    width: 42px;
    height: 42px;
    border: 2px solid #c9d1e2;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        display: none;
    }
    &:after {
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        color: #1f285d;
        line-height: 1;
        font-size: 18px;
    }
}

.CalendarDay__outside {
    opacity: 0.4;
}
.CalendarDay {
    border: 1px solid transparent;
    font-weight: 600;
    color: #1f285d;
    position: relative;
    z-index: 1;
    outline: none !important;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__default:hover,
.CalendarDay__selected :active,
.CalendarDay__selected :hover,
.CalendarDay__selected {
    background: #6f83f7 !important;
    border: 1px solid transparent !important;
    color: #fff !important;
    border-radius: 100%;

    &:after {
        display: none;
    }
}
.DateRangePicker_picker {
    & > div > div > div {
        width: 100% !important;
        background: transparent;
    }
}

.DayPicker__withBorder {
    box-shadow: none !important;
    border: none !important;
}

.CalendarMonth_caption {
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    color: #1f285d;
    padding-bottom: 80px;
    text-transform: capitalize;
}

.DayPicker_weekHeaders {
    display: flex;
    justify-content: space-around;
}

.CalendarDay__hovered_span,
.CalendarDay__selected_span {
    &:after {
        content: "";
        background: url(../../images/home/half_circle.png);
        transform: rotate(-180deg);
        background-size: cover;
        width: 20px;
        height: 42px;
        top: -1px;
        position: absolute;
        top: -1px;
        right: -21px;
        left: auto;
        z-index: -2;
    }
}

.CalendarDay__selected_start {
    & + .CalendarDay__selected_span,
    & + .CalendarDay__hovered_span {
        &:after {
            content: "";
            background: url(../../images/home/half_circle.png);
            transform: rotate(0deg);
            background-size: cover;
            position: absolute;
            top: -1px;
            right: auto;
            z-index: -2;
            width: 20px;
            height: 42px;
            left: -20px;
        }
    }
}
.CalendarDay {
    width: 42px !important;
    height: 42px !important;
}
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__selected_span,
.CalendarDay__selected_span.CalendarDay__blocked_out_of_range {
    background: #edf2fc !important;
    border: 1px solid #edf2fc !important;
    color: #1f285d !important;
    border-radius: 0;
}

.inline_calendar,
.DayPicker {
    .CalendarMonthGrid,
    .DayPicker_transitionContainer,
    table {
        border-collapse: initial;
    }
    tr {
        td:last-of-type:after {
            display: none !important;
        }
    }
}
.DayPicker_weekHeader {
    li {
        border-top: 1px solid #d3ddf2;
        border-bottom: 1px solid #d3ddf2;
        color: #1f285d;
    }
}
.DayPickerNavigation {
    & > div:first-of-type {
        left: 10px;
        &:after {
            content: "\f077";
        }
    }

    & > div:last-of-type {
        right: 10px;
        &:after {
            content: "\f078";
        }
    }
}

@media only screen and (min-width: 768px) {
    .DayPickerNavigation {
        & > div:first-of-type {
            left: 35px;
            &:after {
                content: "\f053";
            }
        }

        & > div:last-of-type {
            right: 35px;
            &:after {
                content: "\f054";
            }
        }
    }

    .DayPicker_weekHeader_ul {
        flex-wrap: nowrap;
        display: flex;
    }
    .DayPicker_weekHeader {
        position: relative;
        top: 120px;
        left: auto !important;
        padding: 0px !important;
        width: 50%;
        display: flex;
        justify-content: center;

        li {
            width: 42px !important;
            height: 42px !important;
            line-height: 42px;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .CalendarMonth {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .DayPickerNavigation_button__horizontalDefault {
        top: 10px;
    }
    .DayPickerNavigation {
        & > div:first-of-type {
            left: 70px;
        }
        & > div:last-of-type {
            right: 70px;
        }
    }
    .DayPicker {
        max-width: 1024px;
        width: 100% !important;
        left: 0;
        right: 0;
        margin: auto !important;
        top: 24%;
    }

    .inline_calendar {
        margin-top: -30px;
    }

    .CalendarMonthGrid,
    .DayPicker_transitionContainer,
    .inline_calendar > div > div > div {
        width: 100% !important;
        background: transparent;
    }
    .CalendarMonthGrid_month__horizontal {
        width: 50%;
    }
    .DayPicker_transitionContainer {
        height: 360px !important;
    }
}

@media only screen and (min-width: 1360px) {
    .DayPicker_transitionContainer {
        height: 444px !important;
    }
    .DayPickerNavigation_button__horizontalDefault {
        top: 0;
    }
    .CalendarDay__selected_start {
        & + .CalendarDay__selected_span,
        & + .CalendarDay__hovered_span {
            &:after {
                width: 26px;
                height: 52px;
                left: -21px;
            }
        }
    }

    .CalendarDay__hovered_span,
    .CalendarDay__selected_span {
        &:after {
            width: 26px;
            height: 52px;
            right: -21px;
        }
    }
    .CalendarDay {
        width: 52px !important;
        height: 52px !important;
    }
    .DayPicker_weekHeader {
        li {
            line-height: 52px;
            font-weight: 600;
            font-size: 16px;
            width: 52px !important;
            height: 52px !important;
        }
    }
    .DayPickerNavigation_button {
        width: 52px;
        height: 52px;
    }
}
.DayPickerNavigation__verticalDefault {
    top: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 767px) {
    .DateRangePicker_picker__portal {
        z-index: 22;
    }
    .range_wrapper_d {
        position: relative;
        &:before,
        &:after {
            font-size: 14px;
            line-height: 22px;
        }

        &:before {
            content: attr(labelinput1);
        }

        &:after {
            content: attr(labelinput2);
            position: absolute;
            top: auto;
            bottom: 58px;
            left: 0;
            transform: translateX(0);
        }

        .DateRangePicker {
            .DateRangePickerInput {
                flex-wrap: wrap;
            }
            .DateInput {
                width: 100%;
                flex-shrink: 0;
                margin-bottom: 15px;

                &:first-of-type {
                    padding-right: 0;
                    order: 1;
                    margin-bottom: 36px;
                }

                &:last-of-type {
                    padding-left: 0;
                    order: 3;
                }
                input {
                    height: 44px;
                }
            }

            .DateRangePickerInput_calendarIcon {
                left: inherit;
                margin-left: 0;
                top: auto;
                bottom: 25px;
                right: 15px;
                display: inline;
                width: auto;
            }
            .DateRangePickerInput_arrow {
                top: 12px;
            }
        }
    }
    .DayPicker .CalendarMonthGrid {
        width: 320px !important;
    }
}
