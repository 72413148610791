.advantages_box {
    background: #edf2fc;
    border-radius: 80px 80px 0px 0px;
    padding: 80px 0;
}

.title {
    text-align: center;
    max-width: 496px;
    width: 100%;
    margin: 0 auto;
    color: #1f285d;
    margin-bottom: 68px;
}
.box_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.small_box,
.big_box {
    background: #fff;
    border-radius: 20px;
    width: 100%;
    margin: 0 20px;

    .section_title {
        border-radius: 20px 20px 0 0;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        padding: 30px 40px;
    }

    .content {
        border-radius: 0 0 20px 20px;
    }
    p {
        padding: 24px 0;
        border-bottom: 1px solid rgba(35, 42, 74, 0.1);
    }
}

.small_box {
    max-width: 448px;
    overflow: hidden;

    .section_title {
        color: #1f285d;
        background: #d3ddf2;
    }

    .content {
        padding: 16px 40px;
    }
    p {
        &:last-of-type {
            border: none;
        }
    }
}

.big_box {
    max-width: 496px;
    position: relative;
    box-shadow: 0px 20px 60px #202a5c33;

    .logo {
        position: absolute;
        right: -40px;
        top: -40px;
    }
    .section_title {
        background: #6f83f7;
        color: #ffffff;
    }
    .content {
        padding: 36px 40px;
    }
    p {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #1f285d;
    }

    .check_icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #dde5ff;
        margin-right: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .link_wrapper {
        margin-top: 10px;
        text-align: center;
        .link {
            color: #6f83f7;
            font-weight: 600;
            margin-top: 24px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.advantages_list {
    background: transparent linear-gradient(180deg, #edf2fc 0%, #ffffff 100%);
    padding: 62px 0 120px;
}

.list_wrapper {
    display: flex;
    justify-content: space-around;
}

.single_list {
    max-width: 368px;
    width: 100%;

    .section_tilte {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 44px;
    }

    .number {
        color: #6f83f7;
        margin-bottom: 9px;
    }

    h5 {
        color: #1f285d;
        font-size: 18px;
        font-weight: 600px;
    }

    .circle {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 30px;
            height: 30px;
        }
    }
}
@media only screen and (max-width: 1400px) {
    .single_list {
        max-width: 320px;
    }
}
@media only screen and (max-width: 1200px) {
    .single_list {
        margin: 0 30px;
    }
}
@media only screen and (max-width: 1023px) {
    .advantages_box {
        padding: 40px 20px;
    }

    .title {
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 36px;
        b {
            font-weight: 600;
        }
    }

    .box_wrapper {
        flex-direction: column;
    }
    .big_box,
    .small_box {
        margin-bottom: 20px;

        .content {
            padding: 6px 30px;
        }
        .section_title {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            padding: 30px;
        }
    }

    .big_box {
        .content {
            padding: 6px 30px 30px;
        }
        .logo {
            position: static;
            width: 52px;
        }
        .section_title {
            display: flex;
            justify-content: space-between;
        }
        p {
            font-size: 14px;
        }

        .check_icon {
            margin-right: 15px;
        }
    }
    .advantages_list {
        padding: 20px 0 0;
    }
    .list_wrapper {
        flex-direction: column;

        .single_list {
            margin: 0 auto 60px;
            padding: 0 30px;
        }
        .section_tilte {
            margin-bottom: 42px;
        }
        .number {
            color: #72778d;
            font-weight: 300;
        }
        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
        p {
            font-weight: 300;
        }
        .circle {
            width: 52px;
            height: 52px;
        }
    }
}
