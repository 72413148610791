.info_sections {
    padding: 20px 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: normal;
        color: #000000;
        margin-bottom: 5px;
    }

    .number {
        font-weight: bold;
        font-size: 90px;
        line-height: 110px;
        color: #000000;

        span {
            font-size: 38px;
            line-height: 56px;
        }
    }
    a {
        align-self: flex-end;
    }

    .year {
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
    }
    .month {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #000000;

        p {
            min-width: 130px;
            display: inline-block;
        }
        i {
            position: relative;
            top: 1px;
            padding: 0 15px;
            cursor: pointer;
        }
    }
}

.chart_wrapper {
    overflow: scroll;
    text-align: center;
}

.chart_wrapper_inner {
    width: 100%;
    min-width: 600px;
    height: 500px;
}

@media only screen and (max-width: 769px) {
    .chart_wrapper_inner {
        height: 300px;
    }
}
