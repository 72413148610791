.buttons_wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        margin-top: 0;
    }
}
.price_wrapper {
    padding: 50px 24px;
}
