.google_baner {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto 60px;
    background: #edf2fc;
    border-radius: 20px;
    padding: 40px 128px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        text-decoration: underline;
        font-weight: 600;
        color: #6f83f7;
    }
}
.text_wrapper {
    max-width: 352px;
}

.title {
    font-weight: 500;
    color: #1f285d;
    margin-bottom: 10px;
}
.text {
    margin-bottom: 20px;
}
.score {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.val_wrapper {
    text-align: center;
    margin-right: 62px;
}

.val {
    color: #6f83f7;
    margin-bottom: 10px;
}

.starts {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    img {
        margin-right: 5px;
    }
}

.g_logo {
    box-shadow: 0px 10px 40px #202a5c33;
    border-radius: 50%;
}

@media only screen and (max-width: 1023px) {
    .google_baner {
        padding: 40px 30px;
        flex-direction: column-reverse;
        margin-left: 20px;
        margin-right: 20px;
        width: auto;
    }

    .score {
        width: 100%;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-bottom: 38px;
    }
    .val_wrapper {
        margin-right: 0;
        text-align: right;
    }
    .starts img {
        margin-right: 0;
        margin-left: 5px;
        font-weight: 300;
    }
    .val {
        font-size: 32px;
        line-height: 40px;
    }
    .reviews {
        font-weight: 300;
    }
    .text_wrapper {
        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }
        .text {
            font-weight: 300;
        }

        a {
            font-weight: 300;
        }
    }
}
