.btn-large {
    border-radius: 4px;
    height: 56px;
    padding: 14px 56px 18px;
    margin-top: 20px;

    line-height: 24px;
    text-align: center;
    position: relative;
    border: 1px solid transparent;
}

.btn {
    line-height: 31px;
    border-radius: 4px;
    height: 40px;
    padding: 3px 31px 4px;
    margin-top: 24px;
    text-align: center;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;
    color: #ffffff;
    &:first-letter {
        text-transform: uppercase;
    }

    &.small {
        height: 30px;
        padding: 3px 10px 2px;
        font-size: 12px;
        line-height: 20px;
    }
}
button {
    &:first-letter {
        text-transform: uppercase;
    }
}
button:focus {
    outline: none;
}
button:disabled,
button[disabled] {
    opacity: 0.5;
}
.btn-submit {
    background: #15c67b;
    color: #fff;
    &:hover {
        background: #00b167;
    }
}
.btn-cancel {
    background: red;
    color: #fff;
    &:hover {
        background: red;
        color: #fff;
    }
}
.btn-cancel-plain {
    color: #15c67b;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: capitalize;
    display: inline-block;
    text-decoration: none;
    &:first-letter {
        text-transform: uppercase;
    }

    &:hover {
        color: #15c67b;
    }
}
.btn-submit:hover {
    color: #fff;
}

.btn.focus,
.btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.btn-green {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    background: #15c67b;
    border-radius: 4px;
    margin-top: 0;
    padding: 8px 20px;
    border: 0;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: #fff;
    }
}

.btn-warning {
    background: #ffc720;
    color: #000 !important;
    &:hover {
        background: #ffc720;
        color: #000 !important;
    }
}
.btn-purple {
    background: #d42aff;
    color: #ffffff !important;
    &:hover {
        background: #d42aff;
        color: #ffffff !important;
    }
}

.btn-assign {
    background: #d42aff;
    color: #ffffff !important;
    padding: 8px 25px;
    height: auto;
    &:hover {
        background: #d42aff;
        color: #ffffff !important;
    }
}

.btn-orange {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    background: #e39403;
    border-radius: 4px;
    margin-top: 0;
    padding: 8px 20px;
    border: 0;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: #fff;
    }
}
.simple-btn {
    border: 1px solid rgba(102, 102, 102, 0.3);
    border-radius: 4px;
    font-weight: 600;
    color: #111111;
    background: #fff;
    transition: 0.5s;

    &:hover {
        border: 1px solid rgba(102, 102, 102, 1);
    }
}

.btn-warning:hover {
    color: #fff;
}

td {
    font-weight: normal;

    line-height: 24px;
    color: #040420;
}

.box {
    padding: 2px 12px;
    display: inline-block;

    font-weight: normal;
    font-size: 12px;
    border-radius: 24px;
    line-height: 18px;
    &:first-letter {
        text-transform: uppercase;
    }
}

.box_lightBlue {
    background: #b0dbff;
    border: 1px solid #008aff;
    color: #008aff;
}

.box_ping {
    background: #f0d8f6;
    border: 1px solid #d42aff;
    color: #d42aff;
}

// .box_blue {
//     background: #589dd8;
//     border: 1px solid #3174ad;
//     color: #fff;
// }

.box_yellow {
    background: #fefe2a;
    border: 1px solid #ffac3a;
    color: #ffac3a;
}
.box_orange {
    background: #ffe9ca;
    border: 1px solid orange;
    color: orange;
}

.box_red {
    background: #ff7280;
    border: 1px solid #d32f2f;
    color: #ffffff;
}

.box_green {
    background: #c8e6c9;
    border: 1px solid #689f38;
    color: #143707;
}

.plane-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.svg-calLoader {
    width: 230px;
    height: 230px;
    transform-origin: 115px 115px;
    animation: 1.4s linear infinite loader-spin;
}

.cal-loader__plane {
    fill: #040484;
}
.cal-loader__path {
    stroke: #ffffff;
    animation: 1.4s ease-in-out infinite loader-path;
}

@keyframes loader-spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes loader-path {
    0% {
        stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
    50% {
        stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
    }
    100% {
        stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
    }
}

@media only screen and (max-width: 769px) {
    .btn {
        padding: 3px 10px 4px;
    }
}

.blue_btn_h,
.green_btn_h,
.grey_btn_h {
    line-height: 18px;
    color: #ffffff;
    padding: 12px 50px;
    border-radius: 24px;
    display: inline-block;
    &:hover {
        color: #ffffff;
    }
}
.green_btn_h {
    background: #30d193;
    border: 2px solid #30d193;
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}
.green_btn_h + .grey_btn_h {
    margin-left: 20px;
}
.grey_btn_h {
    color: #1f285d;
    border: 2px solid #c9d1e2;
    background: transparent;
    &:hover {
        color: #1f285d;
    }
}

.blue_btn_h {
    background: #6f83f7;
    border: 2px solid #6f83f7;
}

@media only screen and (max-width: 1023px) {
    .green_btn_h + .grey_btn_h {
        margin-left: 0;
        margin-top: 15px;
    }
}
