.client_type {
    h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #1f285d;
        margin-bottom: 30px;
    }
}

.client_type_inner {
    display: flex;
    margin: 0 -10px;
}
.login_wrapp,
.register_wrapp {
    width: 50%;
    margin: 0 10px;
    border-radius: 20px;
    padding: 30px 50px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.register_wrapp {
    border: 1px solid #c9d1e1;
    align-items: flex-start;
}
.login_wrapp {
    // background: #f6f8fd;
    border-radius: 20px;
    border: 1px solid transparent;
}

.sub_title {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #1f285d;
    max-width: 110px;
}

.title {
    font-size: 24px;
    line-height: 32px;
    color: #6f83f7;
    margin-bottom: 38px;
}

.text {
    color: #72778d;
}

.forgot_pass_link {
    font-weight: 500;
    color: #72778d;
    .link,
    span {
        margin-left: 33px;
        text-decoration: underline;
        font-weight: 600;
        color: #1f285d;
        cursor: pointer;
    }
}

.reg_btn {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    font-weight: 600;
    color: #6f83f7;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .client_type {
        h4 {
            display: none;
        }

        button {
            display: block;
            width: 100%;
        }
    }
    .client_type_inner {
        margin: 0;
        flex-direction: column;
    }

    .login_wrapp,
    .register_wrapp {
        width: 100%;
        margin: 0;
        background: transparent;
        padding: 0;
        height: auto;
    }

    .sub_title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #1f285d;
        margin-bottom: 30px;
    }
    .forgot_pass_link {
        display: flex;
        align-items: center;
        margin-top: 19px;
        margin-bottom: 90px;
        span {
            margin-left: auto;
        }
    }

    .register_wrapp {
        padding: 20px;
        border: none;
        background: #f6f8fd;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        .sub_title {
            max-width: 100%;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1f285d;
        }
        button {
            width: auto;
        }
        & > div {
            display: none;
        }
        .text,
        .title {
            display: none;
        }
    }
}
