.inner {
    background: #fff;
    padding: 48px 40px 0 40px;
    max-width: 800px;
    width: 100%;
    position: relative;
    form {
        height: 70vh;
        overflow: scroll;
        padding: 0 20px;
        margin: 0 -20px;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
    }

    h4 {
        margin-top: 8px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        font-weight: normal;
    }
}

.close {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
}
.cancel_btn {
    float: right;
}
.btn-cancel {
    color: #15c67b;
}
.sub_title {
    margin-bottom: 0;
    font-size: 18px;
}
.status_wrapper {
    display: flex;

    & > div {
        margin-right: 50px;
    }
}

.name {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #111111;
    margin-bottom: 6px;
}

.personal_table {
    width: 100%;
    table-layout: fixed;

    td {
        width: 50%;
        padding: 4px 0;
        height: 30px;
        vertical-align: middle;
        word-break: break-word;

        &:first-of-type {
            color: rgba(102, 102, 102, 0.7);
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
        }

        &:last-of-type {
            font-size: 14px;
            line-height: 150%;
            color: #111111;
        }
    }
}

.buttons_wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        margin-top: 0;
    }
}
.nav {
    li {
        .menu_item {
            font-size: 14px;
            font-weight: bold;
            line-height: 150%;
            margin-right: 32px;
            padding: 6px 0;
            cursor: pointer;
            border-bottom: 3px solid transparent;
            transition: 0.5s;

            &.active {
                border-bottom: 3px solid #15c67b;
            }
        }
    }
}

.small_text {
    margin-left: 10px;
    font-size: 12px;
}
.price {
    font-size: 64px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}

.image_holder {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin-top: 10px;

    * {
        cursor: pointer;
    }
    p {
        border: 1px solid rgba(102, 102, 102, 0.3);
        border-radius: 4px;
        font-weight: 600;
        color: #111111;
        background: #fff;
        transition: 0.5s;
        line-height: 31px;
        border-radius: 4px;
        height: 40px;
        padding: 3px 31px 4px;
        display: inline-block;
        i {
            margin-right: 5px;
        }
    }
    input {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
    }
}
.company_data {
    text-align: right;
}
.image_wrapper {
    display: flex;
    flex-wrap: wrap;

    .single_image {
        margin: 10px;
        position: relative;
        display: inline-block;

        button {
            margin-top: 0;
            height: 25px;
            padding: 1px 8px 2px;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 10px;
            z-index: 10;
        }
    }
    .image {
        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.label {
    background: #0dcaf0;
    padding: 10px;
    border-radius: 9px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}
@media only screen and (max-width: 769px) {
    .buttons_wrapper {
        flex-direction: column;

        .btn {
            margin-top: 10px;
        }
    }
    .inner {
        padding: 20px !important;
        h3 {
            margin-bottom: 10px;
        }
    }
    .label {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: -1rem !important;
        line-height: 16px;
    }
    .company_data {
        text-align: left;
    }
    .price {
        display: block;
        font-size: 24px;
        margin-top: 20px;
    }
}
