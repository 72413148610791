.confirmDelete {
    .inner {
        background: #fff;
        padding: 48px 40px 30px !important;
        max-width: 600px;
        width: 100%;
        position: relative;
    }

    .close {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;
    }

    .text {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
        margin-bottom: 25px;
    }

    .buttons {
        text-align: right;
    }

    .btn,
    .red_btn {
        border-radius: 4px;
        color: #111111;
        width: 100px;
        height: 40px;
        transition: 0.5s;
    }

    .btn {
        background: transparent;
        border: 1px solid rgba(102, 102, 102, 0.5);
        margin-right: 10px;
    }

    .red_btn {
        color: #fff;
        background: #fd5c5c;
        border: 1px solid #fd5c5c;
    }
}
