.list_table_wrapper {
    .header {
        padding: 16px;
        display: flex;
        align-items: center;

        .result {
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
            text-align: right;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: rgba(17, 17, 17, 0.5);
        }
    }

    .search_bar {
        background: #ffffff;
        border: 1px solid rgba(17, 17, 17, 0.15);
        box-sizing: border-box;
        border-radius: 100px;
        display: flex;
        overflow: hidden;
        padding: 0 12px;
        margin-right: 16px;
        width: 290px;

        img {
            margin-right: 9px;
        }

        input {
            border: none;
            width: 100%;
            height: 34px;
            line-height: 34px;

            &::placeholder {
                font-size: 13px;
                line-height: 150%;
                color: #111111;
                opacity: 0.3;
            }
        }
    }

    .filters {
        display: flex;

        .select {
            margin-right: 24px;
            background: rgba(161, 232, 202, 0.3);
            border-radius: 4px;
            .MuiSelect-root {
                padding: 4px 30px 4px 6px;
                color: #111111;
                font-size: 14px;
                line-height: 150%;
                position: relative;
            }

            & > div {
                padding: 0;
                background-color: transparent !important;
            }
            &:before {
                display: none;
            }

            &:after {
                display: block;
                content: "";
                background: url("../../images/icons/filter-arrow-down-icon.svg");
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                right: 6px;
                top: 6px;
                position: absolute;
                transform: scaleX(1);
                border-bottom: none;
                left: auto;
                bottom: auto;
            }
            svg {
                display: none;
            }
        }
    }

    .footer {
        background: #fbfbfb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px;

        .result {
            font-size: 10px;
            line-height: 145%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #666666;
            font-weight: bold;
        }

        .pagination {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 145%;
            color: #666666;

            input {
                width: 24px;
                height: 24px;
                background: #ffffff;
                border: 1px solid rgba(17, 17, 17, 0.15);
                border-radius: 3px;
                margin-right: 8px;
                text-align: center;
            }

            .arrow {
                padding: 6px 12px;
                cursor: pointer;
                &.disabled {
                    opacity: 0.2;
                    cursor: inherit;
                }
            }
        }

        .per_page {
            font-size: 12px;
            line-height: 145%;
            color: #666666;
            display: flex;
            align-items: center;
            p {
                margin-right: 8px;
            }
        }

        .select {
            background: #ffffff;

            .MuiSvgIcon-root {
                width: 20px;
                height: 20px;
                top: calc(50% - 11px);
            }
            .MuiSelect-root {
                font-size: 12px;
                line-height: 145%;
                color: #111111;
                padding: 4px 17px 4px 7px;
                border: 1px solid rgba(17, 17, 17, 0.15);
                border-radius: 3px;
            }

            & > div {
                padding: 0;
                background-color: transparent !important;
            }
            &:after,
            &:before {
                display: none;
            }
        }
    }
}

.list_table {
    width: 100%;

    .sort_icon {
        background-image: url(../../images/icons/sort-list-icon.svg);
        background-position: center;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }

    .desc {
        .sort_icon {
            height: 8px;
            background-position: bottom center;
        }
    }

    .asc {
        .sort_icon {
            height: 8px;
            background-position: top center;
        }
    }

    th {
        font-weight: 600;
        font-size: 10px;
        line-height: 150%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        position: relative;
        padding: 11px 26px 10px 16px;
        border-top: 1px solid rgba(17, 17, 17, 0.1);
        border-bottom: 1px solid rgba(17, 17, 17, 0.1);
        cursor: pointer;
        &:last-of-type {
            text-align: right;
        }
    }
}

.MuiPopover-root {
    .filters_items {
        background: #f2fef9;

        .MuiListItem-root.Mui-selected,
        .MuiListItem-root.Mui-selected:hover {
            background-color: rgb(227, 248, 239);
        }
    }

    .per_page_items {
        border: 1px solid rgba(17, 17, 17, 0.15);
    }
}
