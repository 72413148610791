.price_calc_wrapper {
    .inner {
        width: 100%;
        position: relative;
        padding: 32px 64px;
        border-radius: 20px;
        border: 1px solid #dce5ff;
        background: #fff;

        @media (max-width: 992px) {
            width: auto;
            margin-left: -10%;
            margin-right: -10%;
            padding: 32px 16px;
        }
    }

    .calcInput {
        margin-bottom: 0;
        padding: 16px 30px;
        height: 52px;
        border: 2px solid #c9d1e1;
        border-radius: 26px;
        background: transparent;
        box-sizing: border-box;
        width: 100%;

        @media (max-width: 992px) {
            margin-bottom: 8px;
        }
    }

    h4 {
        margin-top: 24px;

        font-weight: normal;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #040420;
        margin-bottom: 16px;
    }
}

.priceResult {
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;

    .val {
        font-size: 50px;
        line-height: 44px;
    }
    .small {
        font-size: 16px;
    }
}

.btn_wrapper {
    margin-top: 16px;
    text-align: right;
    a {
        width: 100%;
        text-align: center;
    }
}
