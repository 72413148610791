.pricing_section {
    padding: 0 0 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_wrapper {
    max-width: 624px;
    width: 100%;
    margin-right: 144px;
}

.title {
    margin-bottom: 46px;
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    color: #1f285d;
}

.text {
    font-size: 18px;
    margin-bottom: 24px;
    max-width: 434px;
}

.buttons {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    > * {
        margin-bottom: 10px;
    }
}
.pricing_table {
    max-width: 500px;
    width: 100%;
}
.section_title {
    margin-bottom: 0;
    border-radius: 20px 20px 0px 0px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding: 30px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6f83f7;
    color: #fff;

    .circle {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.inner_table {
    padding: 40px 60px 60px;
    box-shadow: 0px 20px 60px #202a5c33;
    border-radius: 0px 0px 20px 20px;
    .days {
        padding: 14px 0;
        border-bottom: 1px solid rgba(35, 42, 74, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-of-type {
            border: none;
        }
    }

    .name {
        color: #23294b;
    }
    .val {
        color: #6f83f7;
    }
    .rest {
        margin-top: 25px;
        text-align: center;
    }
}

@media only screen and (max-width: 1400px) {
    .pricing_section {
        padding: 0 30px 120px;
    }
}
@media only screen and (max-width: 1023px) {
    .pricing_section {
        flex-direction: column;
        padding: 30px 20px 60px;
    }
    .text_wrapper {
        margin-right: 0;
        padding-left: 30px;
        padding-right: 30px;
    }

    .title {
        font-weight: normal;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 30px;
    }

    .text {
        line-height: 24px;
        margin-bottom: 30px;
    }
    .buttons {
        * {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .pricing_table {
        margin-top: 20px;
    }

    .section_title {
        padding: 15px 15px 15px 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }
    .circle {
        width: 52px;
        height: 52px;
    }

    .inner_table {
        padding: 20px 30px 30px;
    }
}
