.calendar_wrapper {
    height: 85vh;
    background: #fff;

    .rbc-date-cell {
        display: flex;
        justify-content: flex-end;
    }
    .hollyday-label {
        margin-right: auto;
        text-align: left;
        margin-left: 5px;
    }

    .rbc-event-label {
        display: none;
    }

    .rbc-time-content {
        border-top: none;
    }
    .rbc-day-slot {
        .rbc-event {
            position: static;
            height: auto !important;
            margin-bottom: 2px;
            padding: 5px 10px !important;
            font-size: 12px;
            width: 100% !important;
            strong {
                margin-bottom: 3px;
                display: block;
            }
        }
    }
    .rbc-allday-cell {
        display: none;
    }
    .rbc-events-container {
        margin-right: 0;
    }
    .rbc-current-time-indicator,
    .rbc-time-header-gutter,
    .rbc-time-gutter {
        display: none;
    }
    .rbc-time-column .rbc-timeslot-group {
        display: none;
    }
}
// .rbc-day-slot {
//     .rbc-events-container {
//         display: grid;
//         grid-template-rows: repeat(24, 40px);
//     }

//     .rbc-event {
//         position: static;
//     }
// }
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
    outline: none;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
    cursor: inherit;
}
.calendar-toolbar {
    padding: 10px 15px;
    display: block;

    .view-switch-container {
        text-align: right;
    }

    .legend {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: left;
        margin-left: 10px;
        float: left;
        margin-right: 20px;
        text-transform: capitalize;
        li {
            display: flex;
            align-items: center;
            padding: 0;
            font-size: 12px;
        }

        .marker {
            width: 15px;
            height: 15px;
            border: 1px solid #e6e6e6;
            display: inline-block;
            margin-right: 10px;
        }

        &.box {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
            }
        }
    }
}

.calendar-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .toolbar-navigation-button {
        padding: 0 10px;
    }

    .current-month {
        font-size: 18px;
        padding: 5px;
        text-transform: capitalize;
        color: #000;
        border-radius: 3px;
        max-width: 200px;
        width: 100%;
    }
}

.rbc-off-range {
    color: transparent;
    pointer-events: none;
}

// @media only screen and (min-width: 992px) {
//     * {
//         -webkit-touch-callout: none; /* iOS Safari */
//         -webkit-user-select: none; /* Safari */
//         -khtml-user-select: none; /* Konqueror HTML */
//         -moz-user-select: none; /* Old versions of Firefox */
//         -ms-user-select: none; /* Internet Explorer/Edge */
//         user-select: none;
//     }
// }
.event-day-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    line-height: 16px;

    .id-wrapper {
        width: 100px;
        span {
            margin-left: 10px;
        }

        .fa-user-slash {
            font-size: 10px;
            margin: 0 10px;
        }
    }
    .valet-wrapper {
        width: 20%;
    }
    .date-wrapper {
        width: 15%;
        text-align: right;
    }
    .parking {
        width: 20%;
        text-transform: capitalize;
        i {
            margin-right: 5px;
        }
    }
}
@media only screen and (max-width: 769px) {
    .calendar-toolbar {
        .view-switch-container {
            margin-top: 10px;
            text-align: center;
        }
    }
}
