label {
    margin-top: 10px;
    margin-bottom: 5px;
}
.simple-input {
    border: 1px solid rgba(17, 17, 17, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    background-color: #fff !important;
    width: 100%;
    padding: 12px 16px;
}

.simple-input::placeholder {
    font-family: Inter;

    color: rgba(0, 0, 0, 0.6);
}

.custom-error {
    color: red;
    margin-bottom: 16px;
}

.simple-input-password {
    border: 1px solid rgba(17, 17, 17, 0.2);
    border-radius: 4px;
    display: flex;

    .simple-input {
        border: transparent;
    }

    .password-icon {
        margin: 16px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
}
