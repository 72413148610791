.inner {
    background: #fff;
    max-width: 600px;
    width: 100%;
    position: relative;

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
        margin-bottom: 30px;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        font-weight: normal;
    }
}

.close {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
}

.btn-cancel {
    color: #15c67b;
}
