.sidebar {
    min-height: 100vh;
    flex-grow: 0;
    flex-shrink: 0;
    width: 220px;
    background-color: #242939;
    transition: all 0.5s;
    overflow: hidden;
    padding: 22px 20px 85px;
    position: fixed;

    .select {
        background: #fff;
        width: 50px;
        height: 30px !important;

        & > div {
            line-height: 100%;
            > div {
                padding: 7.5px !important;
            }
        }
        svg {
            top: calc(45% - 12px);
        }
        svg {
            right: 0 !important;
        }
    }
    .logo_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;

        .logo {
            width: 100px;
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .main_nav {
        list-style: none;
        margin: 0 0 32px 0;
        padding: 0;

        .dropDown_link,
        & > li > a {
            margin: 8px 0;
            display: inline-block;
            font-size: 13px;
            line-height: 150%;
            color: #ffffff;
            padding-left: 28px;
            position: relative;
            cursor: pointer;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: rgba(58, 58, 58, 1);
                transition: 0.5s;
            }

            &.active,
            &:hover {
                &:before {
                    background: #fff;
                }
                text-decoration: none;
            }
        }
    }

    .dropDown_link {
        display: flex;
        align-items: center;

        i {
            margin-left: 20px;
            transition: 0.5s;

            &.show {
                transform: rotate(180deg);
            }
        }
    }

    .dropDown_menu {
        display: none;

        li > a {
            margin: 8px 0;
            display: inline-block;
            font-size: 12px;
            color: #ffffff;
            padding-left: 28px;
            position: relative;
            cursor: pointer;
        }
        &.show {
            display: block;
        }
    }
    .close {
        display: none;
        position: absolute;
        top: 38px;
        right: 20px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
    }
    .mobile_icon {
        color: #fff;
        font-size: 30px;
        margin-left: 20px;
        cursor: pointer;
        display: none;
    }
}
@media only screen and (max-width: 769px) {
    .sidebar {
        width: 100%;
        z-index: 20;
        min-height: auto;
        padding: 22px 20px 22px;

        .mobile_icon,
        .close {
            display: inline-block;
        }
        .logo_wrapper {
            margin-bottom: 0;
        }

        .main_nav_wrapper {
            position: fixed;
            background-color: #242939;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;
            &.show {
                pointer-events: inherit;
                opacity: 1;
            }
            .main_nav {
                padding: 50px 0 !important;
                margin-bottom: 0 !important;
                overflow-y: scroll;
                min-height: 200px;
                height: 85vh;
            }
        }
    }
}
