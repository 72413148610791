body {
    font-family: "Inter", sans-serif;
    color: #72778d;
    font-size: 16px;
    line-height: 24px;
    background-color: #f6f6f6;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
    scrollbar-color: #888 #fff;
}

html {
    overflow-x: hidden;
}
a:hover,
a {
    color: #72778d;
    text-decoration: none;
}

::-webkit-scrollbar {
    // width: 10px;
    width: 0px;
}
body::-webkit-scrollbar {
    width: 10px;
    // width: 0px;
}
::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.page-header {
    // position: fixed;
    position: sticky;
    top: 0;
    z-index: 2;
}

.App {
    display: flex;
}

.slick-slide {
    position: relative;
    z-index: 1;
    &.slick-active {
        z-index: 3;
        a {
            box-shadow: 0px 20px 60px #232a4a33;
        }
    }
}
.wrapper {
    padding: 0 24px 8px;
}
.h-100 {
    height: 100%;
}
.bg {
    background: #ffffff;
    margin-bottom: 16px;
}
.main-container {
    width: 100%;
    margin-left: 220px;
    position: relative;
    overflow: hidden;
}
.wrapper-card {
    position: relative;
}
.MuiPickersDatePickerRoot-toolbar {
    display: none !important;
}
.MuiMenu-paper {
    box-shadow: none !important;
}
.MuiMenuItem-root {
    font-size: 16px !important;
}
.MuiListItem-gutters {
    padding-left: 10px !important;
    padding-right: 12px !important;
}
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
    background-color: #dce5ff !important;
    border-radius: 0 !important;
    color: #0a0a0a !important;
}
.MuiButton-textPrimary {
    color: #15c67b !important;
}
:focus-visible {
    outline: none;
}

.MuiPickersBasePicker-pickerView {
    border-radius: 16px;
    border: 1px solid #c1cbdc;
    background: #f6faff;
}

.MuiPickersCalendarHeader-dayLabel::after {
    background: #f6faff !important;
}

.Mui-disabled {
    cursor: not-allowed !important;
}

a {
    text-decoration: none;
}
.full-width {
    width: 100%;
}

p {
    margin-bottom: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
// .row {
//     margin-left: -8px;
//     margin-right: -8px;
// }
// div[class*="col-"] {
//     padding: 0 8px;
// }
.pb-10 {
    padding-bottom: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    &:first-letter {
        text-transform: uppercase;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.recharts-legend-wrapper {
    bottom: -20px !important;
}
.custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
    .label {
        text-transform: capitalize;
    }
}
@media (min-width: 1600px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1544px;
    }
}
@media (max-width: 1023px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 1024px) {
    body {
        font-size: 14px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 769px) {
    .wrapper-card {
        margin-top: 100px;
    }
    .main-container {
        margin-left: 0;
    }
}
