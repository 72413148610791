.callUs_banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1008px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 128px;
    background: #6f83f7;
    border-radius: 20px;
    opacity: 1;
    margin-bottom: 80px;
    p {
        margin-bottom: 10px;
        color: #fff;
        font-weight: 600;
    }
}
.phone {
    background: #fff;
    padding: 10px 24px 10px 12px;
    border-radius: 28px;
    display: flex;
    align-items: center;

    img {
        border: 2px solid #fff;
        border-radius: 50%;
        margin-right: 14px;
        box-shadow: 0px 3px 6px #00000029;
    }

    a {
        color: #1f285d;
        font-weight: 500;
        display: inline-block;
    }
}
@media only screen and (max-width: 1023px) {
    .callUs_banner {
        margin: 0 auto 60px;
        width: auto;
        max-width: 420px;
        flex-direction: column;
        padding: 40px 30px;
        p {
            font-weight: normal;
        }
    }
    .phone {
        margin-top: 20px;
        width: 100%;
    }
}
@media only screen and (max-width: 480px) {
    .callUs_banner {
        margin: 0 20px 60px;
    }
}
