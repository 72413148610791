.top-section-list {
    min-height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    padding: 0 24px;
    margin-bottom: 24px;

    .btn {
        margin-top: 0;
    }
    .back-arrow {
        margin-right: 20px;
        cursor: pointer;
        display: inline-block;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
        margin-bottom: 0;
    }

    .action-buttons {
        display: flex;
        align-items: center;

        button,
        a {
            margin-left: 10px;
        }
    }
}
@media only screen and (max-width: 769px) {
    .top-section-list {
        height: auto;
        flex-wrap: wrap;
        padding: 20px 24px;
        justify-content: center;
        flex-direction: column;
        .title {
            text-align: center;
        }

        .action-buttons {
            flex-direction: column;
        }
        button,
        a {
            margin-top: 10px !important;
            margin-left: 0;
        }
    }
}
