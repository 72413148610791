.form-switch {
    .form-check-input {
        height: 23px;
        width: 40px;
        border: none;
        background-color: #d5d5d5;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        cursor: pointer;
        &:focus {
            box-shadow: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        }

        &:checked {
            background-color: #15c67b;
        }
    }
}
