.calendar_wrapper {
    height: 85vh;
    background: #fff;
}

.parking_table {
    margin: 10px 0;
    th {
        border-bottom: 1px solid #d1d1d1;
    }
    th,
    td {
        padding: 2px 10px;
        font-weight: normal;
        text-transform: capitalize;
        font-size: 12px;
        line-height: 14px;
    }
}
