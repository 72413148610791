.footer {
    background: #0e1541;
    padding: 50px 0;

    p {
        color: #c9d1e2;
    }
}

.tc {
    text-align: center;
    cursor: pointer;
}
.logo {
    width: 178px;
}

.info {
    max-width: 315px;
}

.contacts_info {
    display: flex;
    justify-content: space-around;
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li:first-of-type {
            color: #6f83f7;
            margin-bottom: 15px;
        }
        li {
            color: #ffffff;
        }
    }
}
.phone_wrapper {
    width: 263px;
    float: right;
}
.email,
.phone {
    border-radius: 28px;
    padding: 10px;
    padding-right: 38px;
    display: flex;
    height: 52px;
    align-items: center;
    background: #fff;

    img {
        border: 2px solid #fff;
        border-radius: 50%;
        margin-right: 14px;
    }
    a {
        color: #1f285d;
        font-weight: 500;
        display: inline-block;
        &:hover {
            color: #1f285d;
        }
    }
}

.phone {
    margin-bottom: 20px;
}

.socket {
    margin-top: 30px;
    border-top: 1px solid rgba(114, 119, 141, 0.5);
    padding-top: 30px;
}

.payments {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
        margin-right: 34px;
    }
}
@media only screen and (max-width: 992px) {
    .footer {
        padding-bottom: 25px;
    }
    .border {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(114, 119, 141, 0.5);
    }
    .logo {
        width: 100px;
        margin: 0 auto;
        display: block;
    }
    .contacts_info {
        padding: 30px 0;
    }
    .phone_wrapper {
        width: 100%;
        float: none;
    }

    .socket {
        text-align: center;
        p {
            margin-bottom: 25px;
            text-align: center;
        }
    }
    .info {
        max-width: 290px;
        margin: 0 auto;
    }
    .payments {
        display: block;
        p {
            font-size: 12px;
            line-height: 18px;
        }
        img {
            margin-right: 0;
            margin-bottom: 8px;
        }
    }
}
