.list_table {
    td {
        padding: 18px 26px 18px 16px;
        font-size: 13px;
        line-height: 150%;
        color: #111111;
        border-bottom: 1px solid rgba(17, 17, 17, 0.1);

        &:last-of-type {
            text-align: right;
        }
    }
    .name_wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        color: #111111;

        &:hover {
            text-decoration: none;
        }
    }

    .proflie_pic {
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: cover;
        margin-right: 16px;
        border-radius: 50%;
        font-weight: 600;

        line-height: 145%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
    }

    .note {
        font-weight: 600;
        font-size: 11px;
        line-height: 150%;
        color: #f37c0e;
    }

    .actions_icons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        i {
            cursor: pointer;
            margin-left: 10px;
        }
    }
}
