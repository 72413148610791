.trust_us_section {
    .image_holder {
        height: 550px;
        background: url(../../../../images/home/trust_us_bg.jpg);
        background-position: center center;
        background-size: cover;
    }
}

.bg {
    background: #1f285d;
    border-radius: 20px;
    padding: 50px 128px 70px;
    position: relative;
    top: -155px;
}

.text_wrapper,
.list {
    max-width: 496px;
    width: 100%;
}
.text_wrapper {
    color: #fff;
}
.title {
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 86px;
}

.sub_title {
    font-weight: 600;
    color: #fff;
}
.text {
    color: #c9d1e2;
}

.list {
    padding: 36px 40px;
    box-shadow: 0px 20px 60px #202a5c33;
    border-radius: 20px;
    background: #ffffff;
    p {
        padding: 24px 0;
        border-bottom: 1px solid rgba(35, 42, 74, 0.1);
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #1f285d;
        &:last-of-type {
            border: none;
        }
    }

    .check_icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #dde5ff;
        margin-right: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a {
        margin-top: 8px;
        display: block;
        text-decoration: underline !important;
        font-weight: 600;
        color: #6f83f7 !important;

        &:hover {
            color: #6f83f7;
            text-decoration: underline;
        }
    }
}
@media only screen and (max-width: 1023px) {
    .trust_us_section {
        .image_holder {
            height: 225px;
            background-position: center top;
        }

        & > div {
            padding: 0 !important;
        }
    }
    .bg {
        border-radius: 40px;
        top: -45px;
        padding: 50px 35px 40px;
    }
    .title {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 57px;
    }
    .sub_title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 0;
    }
    .text {
        text-align: center;
        color: #c9d1e2;
        margin-bottom: 58px;
    }
    .list {
        padding: 15px 30px;
        margin-bottom: 0;

        p {
            font-size: 14px;
        }
        a {
            margin-top: 0;
        }
    }
}
