.parking_section {
    padding-top: 1px;
    padding-bottom: 120px;
    > div > div {
        align-items: center;
    }
}

.text_wrapper {
    max-width: 624px;
    width: 100%;
    margin: 0 auto;
}

.title {
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #1f285d;
    margin-bottom: 86px;
}

.text {
    margin-bottom: 100px;
    color: #72778d;
    font-size: 18px;
    max-width: 434px;
    width: 100%;
}
.map_wrapper {
    max-width: 624px;
    width: 100%;
}
.map {
    width: 100%;
    height: 600px;
    background: url(../../../../images/home/map_s.jpg);
    background-size: cover;
    background-position: center center;
    cursor: pointer;
}

.info {
    padding: 30px 60px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: #fff;
    background: #6f83f7;
    border-radius: 0px 0px 20px 20px;

    img {
        width: 56px;
        height: 56px;
    }

    .info_text {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
        display: flex;
        align-items: center;
    }
}
@media only screen and (max-width: 1023px) {
    .parking_section {
        padding-bottom: 40px;
        > div > div {
            flex-direction: column-reverse;

            > div {
                width: 100%;
            }
        }
    }
    .map_wrapper {
        margin: 0 auto 40px;
    }
    .map {
        height: 250px;
    }

    .info {
        padding: 15px 15px 15px 30px;

        img {
            width: 52px;
            height: 52px;
        }

        .info_text {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .text_wrapper {
        max-width: 624px;
        width: 100%;
        margin: 0 auto;

        a {
            width: 100%;
            display: block;
            text-align: center;
        }
    }

    .title {
        font-weight: normal;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 26px;
    }

    .text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 54px;
        max-width: 100%;
    }
}
