.page_wrapper {
    width: 100%;
    background: #fff;
    text-align: center;
}

.book_section {
    background: #fff;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;

    .inner {
        max-width: 620px;
        margin: 0 auto;
        text-align: center;
    }

    h3 {
        font-weight: normal;
        font-size: 36px;
        line-height: 42px;

        margin-bottom: 9px;
    }

    p {
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;

        color: #040420;
        margin-bottom: 24px;
    }
    a {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        padding: 8px 24px;
        border-radius: 8px;
        display: inline-block;
        margin-bottom: 16px;
        min-width: 200px;
    }
    .btn {
        background: #040484;
        color: #fff;
        border: 1px solid #040484;
    }
    .btn_blank {
        border: 1px solid #040484;
        background: transparent;
    }
}
