.header {
    width: 100%;
    box-shadow: 0px 10px 40px #2328451a;
    z-index: 2;
    border-radius: 0px 0px 20px 20px;

    .header_inner {
        display: flex;
        background: #fff;
        border-radius: 0px 0px 20px 20px;
        overflow: hidden;
    }
    .login_wrapper {
        background: #1f285d;
    }

    .logo_wrapper {
        display: flex;
        align-items: center;
        margin-left: 10%;
        flex-grow: 1;
    }
    .nav_wrapper {
        width: auto;
        flex-grow: 1;
        justify-content: space-between;
    }

    .button_wrapper,
    .nav_wrapper {
        display: flex;
        align-items: center;

        .logo {
            width: 92px;
        }
    }
    .nav_wrapper,
    .lang_selector {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .main_nav {
        display: flex;
        justify-content: center;
        margin: 0;
        list-style: none;
        margin-left: 124px;

        li {
            border-bottom: 4px solid transparent;
            margin-right: 44px;
            img {
                margin-right: 15px;
            }

            &.active {
                border-bottom: 4px solid #6f83f7;
            }
        }
    }

    .button_wrapper {
        justify-content: flex-end;
        padding-right: 20px;
    }

    .lang_selector {
        background: #edf2fc;
        padding-left: 56px;
        flex-shrink: 0;
        button {
            margin-right: 25px;
        }
    }
    .book_now,
    .phone {
        padding-right: 38px;
        margin-right: 20px;
    }

    .login {
        color: #ffffff;
        float: right;
    }
}
.book_now,
.phone {
    border-radius: 28px;
    padding: 10px;
    padding-right: 38px;
    display: flex;

    height: 52px;
    align-items: center;

    color: #fff;
    img {
        border: 2px solid #fff;
        border-radius: 50%;
        margin-right: 14px;
    }
    a {
        color: #ffffff;

        font-weight: 500;
        display: inline-block;
        &:hover {
            color: #fff;
        }
    }
}

.phone {
    background: #6f83f7;
    padding: 8px 24px;
}
.book_now {
    background: #30d193;
    padding: 8px 50px;
}

.lang_selector {
    display: flex;
    align-items: center;
    button {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #1f285d;
        border: none;
        font-weight: 500;
        line-height: 40px;
        text-transform: uppercase;

        &.active {
            color: #fff;
            background: #1f285d;
        }
    }
}
.login_b,
.login {
    padding: 9px 0 10px;
    background: none;
    letter-spacing: 0px;
    opacity: 1;
    border: none;
    width: auto;
    display: inline-block;
    margin-left: 20px;
}

.header_mobile {
    display: none;

    .header_inner {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 10px 40px #2328451a;
        border-radius: 0px 0px 20px 20px;
        position: relative;
        z-index: 3;

        .action_buttons {
            margin-left: auto;
            * {
                line-height: 16px;
                font-size: 14px;
                padding: 7px 20px;
            }

            a {
                margin-right: 20px;
            }
        }
    }

    .logo {
        width: 50px;
    }

    .login {
        float: none;
        margin-left: 0;
    }

    .login_wrap {
        display: flex;
        align-items: center;
    }
    .mobile_icon {
        margin-left: 20px;
        margin-top: -2px;
        span {
            width: 20px;
            height: 3px;
            margin: 2px 0;
            background: #1f285d;
            display: block;
            transition: 0.5s;
        }

        &.show {
            span:nth-of-type(2) {
                display: none;
            }
            span:nth-of-type(1) {
                transform: rotate(45deg);
            }
            span:nth-of-type(3) {
                transform: rotate(-45deg);
                margin-top: -5px;
            }
        }
    }

    .mobile_menu_wrapper {
        margin-top: -60px;
        height: 100vh;
        max-height: -webkit-fill-available;
        background: #f6f8fd;
        padding: 100px 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 0;
        right: 0;
        transition: 0.5s;
        opacity: 0;
        transform: translateY(-20px);
        pointer-events: none;

        &.show {
            pointer-events: initial;
            z-index: 2;
            opacity: 1;
            transform: translateY(0);
            display: flex;
        }
    }
    .lang_selector {
        justify-content: flex-end;
        width: 100%;
        button {
            margin-left: 10px;
        }
    }

    .main_nav {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;

        li {
            padding: 16px 0;

            a {
                font-size: 32px;
                line-height: 24px;
                color: #1f285d;
            }
        }
    }
    .phone {
        width: 100%;
    }
}
@media only screen and (max-width: 1660px) {
    .reservatin_title {
        display: none;
    }
}
@media only screen and (max-width: 1510px) {
    .header {
        .logo_wrapper {
            margin-left: 50px;
        }

        .phone {
            // line-height: 16px;
            // font-size: 14px;
            // padding: 7px 20px;
            // height: auto;

            img {
                width: 20px;
            }
        }
        .main_nav {
            margin-left: auto;
            li {
                margin-right: 22px;
            }
        }

        .lang_selector {
            width: 15%;
            flex-shrink: 0;
            padding-left: 0;
            justify-content: space-around;

            button {
                width: 46px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #1f285d;
                border: none;
                margin-right: 0;
                font-weight: 500;
                line-height: 40px;

                text-transform: uppercase;

                &.active {
                    color: #fff;
                    background: #1f285d;
                }
            }
        }
    }
}
@media only screen and (max-width: 1200px) {
    .header {
        display: none;
    }
    .header_mobile {
        display: block;
    }
}
