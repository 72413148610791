.intro-page {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 50px;
    position: relative;

    .intro-page-inner {
        padding: 140px 135px 110px;
        max-width: 600px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.03);
        border-radius: 3px;

        &.medium {
            padding: 80px 135px;
        }
        &.small {
            padding: 45px 135px;
        }
    }

    .info-text {
        font-size: 14px;
        line-height: 150%;
        color: #666666;
        margin-bottom: 16px;
    }

    .title {
        font-family: Josefin Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 45px;
        color: #000000;
        text-align: center;
        margin-bottom: 24px;
    }

    .mid-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #111111;
        margin-bottom: 24px;
    }

    .sub-title {
        margin-bottom: 8px;
        font-family: Inter;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #111111;
    }

    .link {
        float: right;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: #15c67b;
        margin-top: 8px;
    }

    .footer-text {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        line-height: 130%;
        color: #666666;
        a {
            color: #15c67b;
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 769px) {
    .intro-page {
        padding: 20px;

        .intro-page-inner.medium,
        .intro-page-inner {
            padding: 20px;
        }
    }
}
