.info_popup {
    left: 0 !important;

    .inner {
        background: #fff;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(12px);
        min-height: 450px;
        max-width: 1024px;
        width: 100%;
        position: relative;
        padding: 36px 44px !important;
        border: 3px solid #fff;
        overflow-y: visible !important;
    }

    h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
    }
    &.small_popup {
        .inner {
            max-width: 500px;
            border: 3px solid #fff;
        }
    }

    &.edit_reservation_popup {
        .inner {
            max-width: 720px;
        }
    }
    &.cancel_popup {
        .inner {
            max-width: 530px;
            min-height: auto;
        }

        .introText {
            font-size: 18px;
            line-height: 27px;
            color: #040420;
        }

        .bottom_text {
            margin-top: 8px;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #040420;
            a {
                color: #040420;
                cursor: pointer;
                margin-right: 5px;
                text-decoration: underline !important;
            }
        }
    }

    .top_nav {
        position: absolute;
        top: -47px;
        display: flex;

        padding: 0;
        left: 0;
        right: 0;
        margin: 0 30px;
        list-style-type: none;

        li {
            border-radius: 12px 12px 0px 0px;
            width: 50%;
            padding: 13px 64px;
            height: 47px;
            margin: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            background: #f2f2f2;
            display: inline-block;
            cursor: pointer;

            transition: 0.5s;

            &.active {
                background: #ffffff;
            }
        }
    }

    .close {
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
    }
    h4 {
        margin-top: 24px;
        font-weight: normal;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #040420;
        margin-bottom: 25px;
    }
}

.profile {
    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #040420;
        &:first-letter {
            text-transform: uppercase;
        }

        span {
            background: #f6f6f6;
            border-radius: 4px;

            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #040420;
            padding: 4px 8px;
            margin-left: 8px;
            cursor: pointer;

            i {
                margin-right: 10px;
            }
        }
    }

    .personal_info {
        td {
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #040420;
            width: 50%;
            padding: 6px 0;
            &:first-letter {
                text-transform: uppercase;
            }

            &:first-of-type {
                font-weight: bold;
            }
        }
    }
    .cars_table_wrapper {
        height: 200px;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
    }

    .cars_info {
        width: 100%;

        tr:nth-of-type(odd) {
            background: #f5f5f5;
        }

        th {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;

            word-break: none;
            &:first-letter {
                text-transform: uppercase;
            }
        }

        td {
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #040420;
        }

        td {
            padding: 12px 0;
        }

        th {
            padding: 16px 0;
        }

        i {
            font-size: 12px;
            cursor: pointer;
        }

        th:last-of-type,
        td:last-of-type {
            text-align: right;
            padding-right: 20px;
        }
        th:first-of-type,
        td:first-of-type {
            padding-left: 20px;
        }
    }
}
.add_car {
    margin-top: 15px;
    text-align: right;
    cursor: pointer;

    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #040420;

    i {
        margin-right: 10px;
    }
}
.reservation_table_wrapper {
    height: 370px;
    overflow: scroll;

    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
}
.reservation_table {
    width: 100%;

    tr:nth-of-type(even) {
        background: #f5f5f5;
    }

    th {
        font-style: normal;
        font-weight: normal;
        line-height: 21px;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    th,
    td {
        padding: 9px 5px;
        font-size: 13px;
    }

    i {
        font-size: 12px;
        cursor: pointer;
    }

    th:last-of-type,
    td:last-of-type {
        text-align: right;
        padding-right: 20px;
    }
    th:first-of-type,
    td:first-of-type {
        padding-left: 20px;
    }

    .photos {
        div {
            display: flex;
            align-items: center;
            a {
                display: flex;
                align-items: center;
            }
        }

        img {
            width: 20px;
            margin-right: 5px;
        }
    }
}

.cost_wrapper {
    margin-bottom: 24px;

    .small {
        font-weight: normal;
        font-size: 18px;
        line-height: 33px;
        color: #040420;
    }
    .number {
        font-weight: bold;
        font-size: 72px;
        line-height: 70px;
        color: #2c2cac;
        display: flex;
        align-items: flex-end;
        span {
            font-weight: normal;
            font-size: 24px;
            line-height: 36px;
            color: #040420;
        }
    }
    .bold {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #040420;
    }
}
@media only screen and (max-width: 769px) {
    .info_popup {
        padding: 15px;
        button {
            width: 100%;
        }
        .top_nav {
            position: static;
            top: 0;
            margin: 0;
            li {
                width: auto;
                border-radius: 0px;
                padding: 13px 13px;
            }
        }
        h3 {
            margin-top: 15px;
        }

        &.cancel_popup {
            h3 {
                font-size: 26px;
                line-height: 32px;
            }
        }
        .profile {
            overflow: scroll;
            height: 300px;
            &::-webkit-scrollbar {
                width: 3px;
            }
            &::-webkit-scrollbar-track {
                background: #fff;
                border-radius: 3px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
        }
        .inner {
            max-height: 80vh !important;
            overflow: scroll;
            padding: 15px 25px !important;
        }
        &.edit_reservation_popup {
            .client_forms {
                height: 300px;
                overflow: scroll;
                &::-webkit-scrollbar {
                    width: 3px;
                }
                &::-webkit-scrollbar-track {
                    background: #fff;
                    border-radius: 3px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
            }
            button {
                margin-top: 50px;
            }
        }
    }

    .profile {
        .personal_info {
            td {
                &:first-of-type {
                    padding-right: 10px;
                }
                font-size: 14px;
                line-height: 16px;
            }
        }

        .cars_table_wrapper {
            height: auto;
            table {
                min-width: 360px;
            }
        }

        .cars_info {
            width: 100%;

            th {
                font-size: 14px;
                line-height: 18px;
            }

            td {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    .reservation_table_wrapper {
        height: 330px;
    }
    .reservation_table_wrapper,
    .cars_table_wrapper {
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
    }
    .reservation_table {
        min-width: 800px;
        td {
            font-size: 14px;
            padding: 6px 0;
        }
    }
}
