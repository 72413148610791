.inner {
    background: #fff;
    max-width: 800px;
    width: 100%;
    position: relative;

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
        margin-bottom: 30px;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        font-weight: normal;
    }
}

.close {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
}

.btn-cancel {
    color: #15c67b;
}

.for_approval_table {
    width: 100%;
    min-width: 600px;

    tr:nth-of-type(even) {
        background: #f1f1f1;
    }

    th {
        font-weight: normal;
        padding: 4px 5px;
        height: 38px;

        &:first-of-type {
            padding-left: 15px;
        }
        &:last-of-type {
            padding-right: 15px;
        }
    }

    td {
        height: 38px;
        padding: 4px 5px;

        i {
            margin-right: 10px;
        }
        &:first-of-type {
            padding-left: 15px;
        }
        &:last-of-type {
            width: 1%;
            white-space: nowrap;
            padding-right: 15px;
        }
    }
}
