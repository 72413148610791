.home_page {
    width: 100%;
    background: #fff;
}
a,
button {
    transition: 0.5s;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.book_section {
    background: url(../../../images/home/book-bg.jpg);
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
}

.info_popup {
    left: 0 !important;
    .close {
        position: absolute;
        right: 24px;
        cursor: pointer;
    }
    h4 {
        margin-top: 24px;

        font-weight: 400;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #040420;
        margin-bottom: 16px;
    }
    .inner {
        max-width: 790px;
        position: relative;
    }
    .text_wrapper {
        background: #fff;
        padding: 16px;

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #040420;
        height: 350px;
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
    }
}
@media only screen and (max-width: 767px) {
    .popup_terms {
        .inner {
            padding: 20px !important;
            height: 100%;
            border-radius: 0 !important;
            box-shadow: none !important;
            h4 {
                font-size: 30px;
            }
        }
        .text_wrapper {
            height: 85vh;
        }
    }
}
