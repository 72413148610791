.client_summary {
    padding: 50px 24px;
    .sub_title {
        margin: 0 80px 16px 0;
        font-weight: 600;

        line-height: 150%;
        border-bottom: 1px solid rgba(17, 17, 17, 0.1);
        color: #111111;
        padding-bottom: 8px;
    }

    .summary {
        .personal_table {
            width: 100%;
            table-layout: fixed;

            td {
                width: 50%;
                padding: 4px 0;
                height: 30px;
                vertical-align: middle;

                &:first-of-type {
                    color: rgba(102, 102, 102, 0.7);
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 150%;
                    letter-spacing: 0.07em;
                    text-transform: uppercase;
                }

                &:last-of-type {
                    font-size: 14px;
                    line-height: 150%;
                    color: #111111;
                }
            }
        }

        .text {
            line-height: 150%;
            color: #111111;
        }

        .service {
            font-size: 14px;
            line-height: 150%;
            color: #111111;
            padding: 6px;
            margin-right: 10px;
            margin-bottom: 8px;
            display: inline-block;
            background: rgba(161, 232, 202, 0.3);
            border-radius: 4px;
        }
    }

    .button_wrapper {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 20px;
        }
    }
}

.vehicles_table_wrap {
    overflow: scroll;
    width: 100%;
}
.vehicles_table {
    width: 100%;
    min-width: 500px;
    table-layout: fixed;
    tr {
        border-bottom: 1px solid rgba(159, 159, 159, 0.3);
    }

    th {
        font-weight: normal;
        padding: 4px 5px;
        height: 38px;
        text-align: center;
        font-weight: bold;
        text-transform: capitalize;
    }
    td {
        height: 38px;
        padding: 4px 5px;
        text-align: center;
        i {
            margin-right: 10px;
        }
        &:last-of-type {
            white-space: nowrap;
        }
        input {
            border: 1px solid rgba(17, 17, 17, 0.2);
            box-sizing: border-box;
            border-radius: 4px;
            height: 32px;
            background-color: #fff !important;
            padding: 12px 5px;
            text-align: center;
            font-size: 14px;
        }
    }
    th:first-of-type,
    td:first-of-type {
        text-align: left;
        input {
            text-align: left;
        }
    }
    th:last-of-type,
    td:last-of-type {
        text-align: right;
        input {
            text-align: right;
        }
    }
}
