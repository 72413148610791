.pop {
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity 0.5s;

    .inner {
        transition: transform 0.5s;
        transform: translateY(-20px);
        overflow-y: scroll;
        max-height: 100vh;
        background: #fff;
        padding: 48px 40px 0;
        border-radius: 20px;
    }

    &.show {
        opacity: 1;
        z-index: 22;
        pointer-events: initial;
        .inner {
            transform: translateY(0);
        }
    }
}

@media only screen and (max-width: 769px) {
    .pop {
        left: 0;
        .inner {
            padding: 48px 20px 0;
        }
    }
}
