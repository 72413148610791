.input-border {
    border: 1px solid rgba(17, 17, 17, 0.2);
    border-radius: 4px;
    height: 50px;
    padding: 0 16px;

    .MuiSelect-select:focus {
        background: transparent;
    }

    &.textarea {
        height: 100px;
    }

    label {
        margin-top: 0;
        margin-bottom: 0;
    }
    .MuiAutocomplete-endAdornment {
        top: -3px;
    }
    .autocomplete {
        margin-right: -10px;
        .MuiFormHelperText-root {
            margin-right: 24px !important;
        }
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after {
        display: none !important;
    }
    .MuiInputLabel-formControl {
        transform: translate(0px, 16px) scale(1);
        color: rgba(17, 17, 17, 0.7);
    }

    .MuiInputLabel-shrink {
        transform: translate(0, 8px) scale(0.7);
    }
    .MuiInputLabel-shrink,
    .MuiFormLabel-root.Mui-focused {
        color: rgba(102, 102, 102, 0.8);
    }
    .MuiInput-formControl {
        margin-top: 14px;
    }
    input {
        line-height: 150%;
        display: flex;
        color: #111111;
        height: auto;
        padding: 6px 0 0px;
        font-weight: normal;
        font-weight: 400;
    }
    .MuiFormControl-root {
        display: flex;
    }
    .MuiFormHelperText-root {
        margin-top: -28px;
        text-align: right;
    }

    .MuiAutocomplete-root {
        .MuiFormHelperText-root {
            margin-top: -35px;
        }
    }
}

.textarea-border {
    border: 1px solid rgba(17, 17, 17, 0.2);
    border-radius: 4px;
    height: 100px;
    padding: 0 16px;
    .MuiInput-underline:before,
    .MuiInput-underline:after {
        display: none !important;
    }

    textarea {
        line-height: 150%;
        display: flex;
        color: #111111;
        height: auto;
        padding: 6px 0 0px;
        font-weight: normal;
        font-weight: 400;
    }
    .MuiFormControl-root {
        display: flex;
    }
    .MuiFormHelperText-root {
        margin-top: -28px;
        text-align: right;
    }
}
label::first-letter {
    text-transform: capitalize;
}
