.info_popup {
    left: 0 !important;

    .inner {
        background: #fff;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(12px);

        max-width: 620px;
        width: 100%;
        position: relative;
        padding: 46px 64px !important;
        border: 3px solid #fff;
    }

    .close {
        position: absolute;
        top: 17px;
        right: 17px;
        cursor: pointer;
    }
    h4 {
        margin-top: 24px;

        font-weight: normal;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #040420;
        margin-bottom: 16px;
    }
}

.form_wrapper {
    h3 {
        text-align: center;
        margin-bottom: 10px;
        font-weight: normal;
        font-size: 36px;
        line-height: 42px;
    }
}

.foot_note {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
}

.mb_0 {
    margin-bottom: 0 !important;
}
.introText {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #040420;
    margin-bottom: 16px;
}

.bottom_link {
    margin-bottom: 8px;
    color: #040420;
    text-align: center;

    a,
    span {
        color: #040420 !important;
        text-decoration: underline;
        cursor: pointer;
    }
}

.small_text {
    margin-top: 12px;

    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #040420;

    a,
    span {
        color: #040420 !important;
        text-decoration: underline;
        cursor: pointer;
    }
}
.btn_wrapper {
    text-align: center;
    button {
        width: 100%;
    }
}
@media only screen and (max-width: 769px) {
    .info_popup {
        padding: 0 15px;
        .inner {
            padding: 20px 15px !important;
        }
    }

    .bottom_link {
        line-height: 20px;
    }

    .form_wrapper {
        h3 {
            font-size: 24px;
            line-height: 32px;
        }
    }
    .introText {
        line-height: 18px;
    }

    .bottom_link {
        a,
        span {
            display: block;
        }
    }
}
