.list_table {
    td {
        padding: 18px 26px 18px 16px;
        font-size: 13px;
        line-height: 150%;
        color: #111111;
        border-bottom: 1px solid rgba(17, 17, 17, 0.1);

        &:last-of-type {
            text-align: right;
        }

        img {
            width: 20px;
            margin: 2px;
            cursor: pointer;
        }
    }
}
