.client_forms {
    input {
        font-family: "Inter", sans-serif;
        font-weight: normal;
    }
    button {
        &:first-letter {
            text-transform: uppercase;
        }
    }
    .MuiFormLabel-root.Mui-disabled,
    .MuiInputBase-root.Mui-disabled {
        color: #72778d;
    }
    label {
        margin-bottom: 8px;
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
    }
    fieldset {
        display: none;
    }
    .password_input {
        position: relative;
        margin-bottom: 20px;

        .password_icon {
            position: absolute;
            z-index: 1;
            cursor: pointer;
            top: 16px;
            right: 15px;
        }
    }

    .autocomplete input,
    .input input,
    .password_input input {
        padding-left: 16px !important;
        padding-right: 30px;

        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
    }

    .btn_submit {
        padding: 10px 32px;
        background: #040484;
        border: 1px solid #040484;
        border-radius: 12px;

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        display: block;
        width: 100%;
        color: #fff;
        &:first-letter {
            text-transform: uppercase;
        }
        &.inline {
            display: inline-block;
            width: auto;
        }
    }
    .btn_cancel {
        margin-left: 16px;
        padding: 10px 32px;
        background: #fff;
        border: 1px solid #040484;
        border-radius: 12px;

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        display: block;
        width: 100%;

        &.inline {
            display: inline-block;
            width: auto;
        }
    }

    .input {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        .MuiInput-underline {
            &:before,
            &:after {
                display: none;
            }
        }

        & > div {
            margin-bottom: 0;
            padding: 5px 0;
            height: 52px;
            border: 2px solid #040420;
            box-sizing: border-box;
            border-radius: 12px;
            background: #fff;
            width: 100%;

            &:before,
            &:after {
                display: none;
            }
        }
        p {
            font-size: 1rem;
        }
    }

    .autocomplete {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        .MuiInput-underline {
            margin-top: 0;
            margin-bottom: 0;
            padding: 5px 0;
            height: 52px;
            border: 2px solid #040420;
            box-sizing: border-box;
            border-radius: 12px;
            background: #fff;
            width: 100%;
            &:before,
            &:after {
                display: none;
            }
        }

        p {
            font-size: 1rem;
        }
    }

    .phone_code_wrapper {
        display: flex;
        .phone_code {
            width: 100px;
            input {
                padding-right: 5px;
                padding-left: 5px !important;
                text-align: center;
            }
            & > div {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none;
            }
        }

        .phone {
            & > div {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.with_label {
            .input_label {
                p {
                    font-size: 1rem;
                    padding-left: 0;
                    margin-left: -55px;
                }
                .MuiInputLabel-shrink {
                    transform: translate(-55px, -0.5px) scale(0.75);
                }
            }
        }
    }
    .input_label_select,
    .input_label {
        & > div {
            margin-bottom: 0;
            padding: 8px 8px 8px 16px;
            height: 48px;
            border: 1px solid #d6d4da;
            background: #fbfbfb;
            border-radius: 24px;
            box-sizing: border-box;
            width: 100%;
            &:before,
            &:after {
                display: none;
            }
        }
        p {
            font-size: 1rem;
            padding-left: 0;
        }

        &.phone_code {
            // margin-top: 16px;
            & > div {
                padding: 16px 10px;
            }
        }
        .MuiInputLabel-shrink + div {
            background: #fff !important;
        }
    }
    .input_label_select {
        & > div {
            padding: 16px 0;
        }

        .MuiSelect-select:focus {
            background: transparent;
        }
    }

    .password_input_label {
        position: relative;
        margin-bottom: 20px;

        .password_icon {
            position: absolute;
            z-index: 1;
            cursor: pointer;
            top: 16px;
            right: 30px;
        }
    }
    .autocomplete_label {
        input {
            height: 16px;
            padding: 0 !important;
        }
        .MuiAutocomplete-endAdornment {
            right: 15px;
        }
        .MuiInput-underline {
            margin-bottom: 0;
            padding: 8px 8px 8px 16px;
            height: 48px;
            border-radius: 24px;
            border: 1px solid #d6d4da;
            background: #fbfbfb;
            width: 100%;
        }

        p {
            font-size: 1rem;
        }
    }
    .input_label_select,
    .autocomplete_label,
    .input_label {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        .MuiInput-underline {
            &:before,
            &:after {
                display: none;
            }
        }
        .MuiFormLabel-root.Mui-error {
            color: #1f285d;
        }
        .MuiInputLabel-formControl {
            transform: translate(30px, 36px) scale(1);
        }
        .MuiInputLabel-shrink {
            transform: translate(30px, -1px) scale(0.75);
        }
    }
}

.bottom_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    max-width: 560px;
    width: 100%;

    &.end {
        justify-content: flex-end;
    }
    &.center {
        justify-content: center;
    }
}

.btn_back {
    border: 2px solid #c9d1e2;
    background: #fff;
    border-radius: 26px;
    display: inline-block;
    line-height: 40px;
    color: #ffffff;
    padding: 4px 50px;
    color: #1f285d;
}
.client_checkbox {
    color: #040420;
    font-size: 14px;
    padding-left: 15px;
    margin-bottom: 36px;

    .link {
        cursor: pointer;
        text-decoration: underline;
    }
    .fas.fa-circle {
        color: transparent;
        border: 2px solid #c9d1e1;
    }
    i {
        border: 2px solid transparent;
        border-radius: 50%;
    }
    .MuiIconButton-root {
        color: #30d193 !important;
    }
}
.input_with_icon {
    position: relative;
    cursor: pointer;
    * {
        pointer-events: none;
    }
    input {
        color: #000 !important;
    }
    i {
        position: absolute;
        top: 15px;
        font-size: 20px;
        right: 20px;
    }
}
.input_withicon_Label {
    position: relative;
    cursor: pointer;
    * {
        pointer-events: none;
    }

    i {
        position: absolute;
        top: 32px;
        font-size: 20px;
        right: 20px;
    }
}
.my_label {
    font-size: 14px;
}
@media only screen and (max-width: 767px) {
    .client_forms .input_label_select .MuiInputLabel-shrink + div,
    .client_forms .input_label .MuiInputLabel-shrink + div {
        background: transparent !important;
    }
    .client_forms .input_label input {
        font-size: 16px !important;
        color: #72778d !important;
    }

    .blue_btn_h {
        background: #6f83f7;
        border: 2px solid #6f83f7;
    }

    .autocomplete_label {
        .MuiInput-underline {
            padding: 16px 20px;
        }
    }
    .input_label_select,
    .input_label {
        & > div {
            padding: 16px 20px;
        }
    }
    .client_forms {
        .input_label_select,
        .autocomplete_label,
        .input_label {
            .MuiInputLabel-formControl {
                transform: translate(20px, 33px) scale(1);
            }
            .MuiInputLabel-shrink {
                transform: translate(20px, -1px) scale(0.75);
            }
        }
    }

    .client_forms .input_label_select > div,
    .client_forms .input_label > div {
        margin-bottom: 0;
        padding: 10px 30px;
        height: 48px;
        border: 2px solid #c9d1e1;
        border-radius: 26px;
        background: transparent;
        box-sizing: border-box;
        width: 100%;
    }

    .client_forms .input_label_select p,
    .client_forms .input_label p {
        padding-left: 20px;
    }
    .bottom_links_wrapper {
        padding: 30px 0 0;
        flex-direction: column;
        button {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .client_forms .input_label_select,
    .client_forms .autocomplete_label,
    .client_forms .input_label {
        margin-bottom: 15px;
    }
}
