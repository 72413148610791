.faq_section {
    background: #edf2fc;
    border-radius: 80px 80px 0px 0px;
    padding: 80px 0 120px;

    h3 {
        font-weight: 300;
        font-size: 24px;
        line-height: 32px;
        color: #1f285d;
        text-align: center;
        margin-bottom: 38px;
    }

    .faq_wrapper {
        max-width: 1006px;
        width: 100%;
        margin: 0 auto;
    }

    .element {
        margin-bottom: 15px;
        border-radius: 20px;
        background: #fff;
        padding: 24px 40px;
        h4 {
            color: #1f285d;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }

        > div {
            margin-top: 25px;
            display: none;
        }

        .carret {
            width: 30px;
            height: 30px;
            margin-left: 20px;
            flex-shrink: 0;
            line-height: 30px;
            border-radius: 50%;
            border: 2px solid #c9d1e1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
        }
        &.open {
            font-size: 18px;
            line-height: 24px;
            box-shadow: 0px 10px 20px #202a5c1a;

            .carret {
                border: 2px solid #6f83f7;
                background: #6f83f7;
                color: #fff;
            }
            h4 {
                i {
                    transform: rotate(-180deg);
                }
            }
            > div {
                display: block;
            }
        }
    }
}

.callUs_banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1008px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 40px;
    background: #6f83f7;
    border-radius: 20px;

    p {
        margin-bottom: 10px;
        color: #fff;
        font-weight: 300;
    }

    b {
        font-weight: 600;
    }
}
.phone {
    background: #fff;
    padding: 10px 24px 10px 12px;
    border-radius: 28px;
    display: flex;
    align-items: center;

    img {
        border: 2px solid #fff;
        border-radius: 50%;
        margin-right: 14px;
        box-shadow: 0px 3px 6px #00000029;
    }

    a {
        color: #1f285d;
        font-weight: 500;
        display: inline-block;
    }
}
@media only screen and (max-width: 1023px) {
    .faq_section {
        border-radius: 40px 40px 0px 0px;
        padding: 40px 0 60px;

        h3 {
            font-size: 18px;
            line-height: 24px;
            color: #1f285d;
        }

        .element {
            padding: 15px 30px;
            h4 {
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #1f285d;
            }

            > div {
                margin-top: 15px;
                font-size: 14px;
                line-height: 22px;
                display: none;
            }
        }
    }

    .callUs_banner {
        margin: 0 auto;
        width: auto;
        max-width: 420px;
        flex-direction: column;
        padding: 40px 30px;
        p {
            font-weight: 300;
        }
    }
    .phone {
        margin-top: 20px;
        width: 100%;
    }
}
