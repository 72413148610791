.nav_wrapper {
    max-width: 560px;
    width: 100%;
    margin: 0 0 64px;

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    li {
        height: 30px;
        img {
            text-align: left;
        }
        @media (max-width: 767px) {
            &:nth-of-type(2),
            &:nth-of-type(4) {
                width: 20%;
                overflow: hidden;
                img {
                    float: right;
                    margin-top: 8px;
                }
            }
        }
    }
    button {
        background: transparent;
        border: none;
        height: 30px;

        span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            color: #72778d;

            @media (max-width: 767px) {
                font-size: 12px;
            }
        }

        .cirlce {
            width: 26px;
            height: 26px;
            margin: 0 auto 4px;
            border-radius: 50%;
            border: 2px solid #72778d;
            background: rgba(193, 203, 220, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            span {
                font-weight: 700;
            }
        }
    }
}

.active {
    .cirlce {
        &:after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #6f83f7;
        }
    }
}
.completed {
    .cirlce {
        background: rgba(48, 209, 147, 0.1) !important;
        border: 2px solid #30d193 !important;

        &:after {
            position: absolute;
            content: "";
            left: 9px;
            top: 4px;
            width: 6px;
            height: 12px;
            border: solid #30d193;
            border-width: 0 2px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.phone {
    margin-top: 56px;
    border-radius: 28px;
    width: 100%;
    padding: 10px;
    padding-right: 38px;
    display: flex;
    background: #ffffff;
    height: 52px;
    align-items: center;

    img {
        border: 2px solid #fff;
        border-radius: 50%;
        margin-right: 14px;
    }
    a {
        color: #1f285d;

        font-weight: 500;
        display: inline-block;
    }
}
