.history_wrapper {
    .image_holder {
        height: 550px;
        background: url(../../../../images/home/history_bg.jpg);
        background-position: center center;
        background-size: cover;
    }
}

.bg {
    background: #1f285d;
    border-radius: 20px;
    padding: 50px 0 70px;
    position: relative;
    top: -155px;
}

.title {
    text-align: center;
    font-weight: 300;
    line-height: 32px;
    font-size: 24px;
    color: #ffffff;
    max-width: 496px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 57px;
    b {
        font-weight: 600;
    }
}

.single {
    max-width: 328px;
    width: 100%;
    margin: 0 auto;
}
.circle {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    background: #a4b9fd;
    border-radius: 50%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text {
    text-align: center;
    font-weight: 300;
    color: #c9d1e2;
}
@media only screen and (max-width: 1200px) {
    .history_wrapper {
        .bg {
            > div {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
@media only screen and (max-width: 1023px) {
    .history_wrapper {
        .image_holder {
            height: 225px;
            background-position: center top;
        }

        & > div {
            padding: 0 !important;
        }
    }
    .bg {
        border-radius: 40px;
        top: -45px;
        padding: 50px 35px 10px;
    }
    .title {
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
    }
    .circle {
        width: 52px;
        height: 52px;
        margin-bottom: 20px;
    }
    .text {
        font-weight: 300;
        color: #ffffff;
        margin-bottom: 58px;
    }
}
