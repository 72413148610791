.row {
    display: flex;
    margin-right: -20px;
    .col {
        width: 50%;
        padding-right: 20px;
    }
}

.dates_picker {
    max-width: 560px;
    width: 100%;
}

.date_holder {
    border-radius: 24px;
    border: 2px solid #c1cbdc;
    height: 48px;
    line-height: 44px;
    color: #72778d;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 8px;

    img {
        margin-top: -6px;
        margin-left: 8px;
        margin-right: 10px;
    }
}

.label {
    margin-top: 32px;
    margin-bottom: 8px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.calendar_picker_holder {
    display: none;

    &.show {
        display: block;
    }
}
