.input-sign {
    border: 1px solid rgba(17, 17, 17, 0.2);
    border-radius: 4px;
    padding: 6px 12px 7px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    margin-top: 4px;

    input {
        font-size: 14px;
        color: #111111;
        width: 100%;
        margin: 0;
        border: none;
    }
    .sign {
        font-size: 14px;
        line-height: 150%;
        color: #111111;
        opacity: 0.34;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after {
        display: none !important;
    }
}
.input-sign-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    label {
        margin-top: 0;
        margin-right: 10px;
        flex-basis: 55px;
        flex-shrink: 0;
        margin-bottom: 0;
    }
}
