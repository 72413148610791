.inner {
    background: #fff;
    max-width: 800px;
    width: 100%;
    position: relative;
    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
        margin-bottom: 30px;
    }

    h4 {
        margin-top: 8px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        font-weight: normal;
    }
}

.close {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
}

.btn-cancel {
    color: #15c67b;
}

.name {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #111111;
    margin-bottom: 6px;
}

.personal_table {
    width: 100%;
    table-layout: fixed;

    td {
        width: 50%;
        padding: 4px 0;
        height: 30px;
        vertical-align: middle;
        word-break: break-word;

        &:first-of-type {
            color: rgba(102, 102, 102, 0.7);
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
        }

        &:last-of-type {
            font-size: 14px;
            line-height: 150%;
            color: #111111;
        }
    }
}

.buttons_wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        margin-top: 0;
    }
}
