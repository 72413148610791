.intro-setup-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;

    .setupintro-page-inner {
        padding: 30px 40px 10px;
        background: #ffffff;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.03);
        border-radius: 3px;
        max-width: 665px;
        width: 100%;
    }

    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #111111;
    }

    .image-holder {
        display: flex;
        align-items: center;
        margin: 20px 0;

        .image {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            background-image: url("../../../images/profile-setUp-bg.svg");
            margin-right: 15px;
            background-size: cover;
        }

        .delete {
            display: flex;
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            color: #666666;
            margin-top: 8px;
            cursor: pointer;
            img {
                margin-right: 7px;
            }
        }

        .text {
            color: #15c67b;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            position: relative;
            display: inline-block;
            cursor: pointer;
        }

        input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            cursor: pointer;
        }
    }
    .sub-title {
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #111111;
        margin-top: 10px;
    }
}
