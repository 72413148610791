.time_picker_wrapper {
    left: 0;

    .inner {
        background: #ffffff;
        background: #f2f2f2;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(12px);

        width: 331px;
        height: 250px;
        position: relative;
        border: 3px solid #fff;
        padding: 46px 24px 24px;
        overflow-y: visible !important;
    }
    .close {
        position: absolute;
        top: 17px;
        right: 17px;
        cursor: pointer;
    }

    .pickers_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .green_btn_h,
    .button_submit {
        margin-top: 30px;
        position: absolute;
        bottom: 24px;
        width: 276px;
        z-index: 2;
    }
    .button_submit {
        padding: 8px;
        background: #040484;
        border: 1px solid #040484;
        border-radius: 12px;

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: block;
        color: #fff;
    }

    .dots {
        margin-top: 8px;

        font-style: normal;
        font-weight: normal;
        font-size: 64px;
        line-height: 75px;
        /* identical to box height */

        /* Blue/500 */

        color: #545454;
    }
    .time_picker_with_label {
        position: relative;
        z-index: 2;
        .overlay {
            position: absolute;
            width: 120px;
            height: 100px;
            left: 0;
            right: 0;
            z-index: 2;
            cursor: pointer;
            &.hide {
                pointer-events: none;
            }
        }

        .type {
            position: absolute;
            top: 75px;
            z-index: 1;
            width: 120px;
            display: block;
            text-align: center;

            font-size: 14px;
            line-height: 16px;

            opacity: 0.5;
        }
    }

    .time_picker_with_label {
        overflow: hidden;
        position: relative;
        &.open {
            overflow: initial;
            z-index: 3;

            .type {
                display: none !important;
            }
        }
    }

    .time_picker {
        position: relative;
        height: 98px;
        top: 0;
        border-radius: 12px;
        width: 120px;
        overflow: hidden;
        cursor: grab;
        &.open {
            height: 444px;
            top: -170px;
            box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(12px);
            .inner-wrap {
                top: 0;
            }
        }
        .inner-wrap {
            position: relative;
            top: -172px;
        }

        .swiper-container {
            height: 444px;
            width: 120px;
            background: #dadae6;
            border-radius: 12px;
        }
        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-slide {
            font-weight: normal;
            font-size: 36px;
            line-height: 42px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 56px !important;

            &.swiper-slide-active {
                font-weight: 600;
                font-size: 48px;
                line-height: 56px;
                color: #000000;
                cursor: pointer;
            }
        }
    }
}
