.title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1f285d;
}

.row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 560px;
    .col {
        width: 100%;
    }
}
.section_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1f285d;
}

@media only screen and (max-width: 767px) {
    .title {
        display: none;
    }
    .form_wrapper {
        background: transparent;
        border-radius: 0;
        padding: 0;
    }
    .row {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .col {
            width: 100%;
        }
    }

    .section_title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #1f285d;
        margin-bottom: 30px;
    }
}
