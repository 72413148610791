.booking_page_wrapper {
    flex-direction: column;
    width: 100%;
    background: #ffffff;
}

.book_form {
    width: 100%;
    min-height: 700px;
    margin: 50px auto;
    position: relative;
    display: flex;
}
.arrow_back_btn {
    margin: 64px 64px 0;
    display: inline-flex;
    align-items: center;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    img {
        margin-right: 4px;
    }

    @media (max-width: 767px) {
        display: none;
    }
}
.main_wrapper {
    padding: 0 64px 42px;
    width: 100%;
}
.info_popup {
    left: 0 !important;

    .inner {
        background: #ffffff;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(12px);
        border-radius: 0px 8px 8px 8px;
        width: 100%;

        position: relative;
        padding: 24px !important;
        overflow-y: visible !important;
    }

    .close {
        position: absolute;
        top: 17px;
        right: 17px;
        cursor: pointer;
    }

    &.small_popup {
        .inner {
            max-width: 500px;
            border: 3px solid #fff;
        }

        h3 {
            text-align: center;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
        }
    }
}

.calcInput {
    width: 100%;
    height: 56px;
    background: #ffffff;
    border: 2px solid #040420;
    box-sizing: border-box;
    border-radius: 12px;
    padding-left: 16px !important;
    padding-right: 30px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}
.final_step {
    background: #1f285d;
    display: flex;
    padding: 144px 128px 115px;
    width: 100%;
    justify-content: space-between;
    border-radius: 20px;

    .title {
        font-size: 32px;
        line-height: 40px;
        color: #ffffff;
        margin-bottom: 120px;

        span {
            color: #30d193;
        }
    }
    p {
        font-size: 18px;
        color: #d3ddf2;
    }
    .text {
        max-width: 440px;
    }
    .image {
        display: flex;
        align-items: flex-end;
    }
}
.mobile {
    display: none;
}
@media only screen and (max-width: 1400px) {
    .booking_page_wrapper {
        .container {
            max-width: 100%;
        }
    }
    .main_wrapper {
        padding: 35px 25px;
    }
}
@media only screen and (max-width: 1360px) {
    .book_form {
        margin: 75px 0;
    }
}
@media only screen and (max-width: 1023px) {
    .book_form {
        flex-direction: column;
    }
}
@media only screen and (max-width: 767px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .book_form {
        box-shadow: none;
        min-height: auto;
        margin-bottom: 35px;
    }
    .main_wrapper {
        padding: 20px;
    }
    .container {
        padding: 0 !important;
    }

    .final_step {
        margin-top: -100px;
        position: relative;
        padding: 80px 20px 20px;
        flex-direction: column;
        border-radius: 0;
        .text {
            max-width: 100%;
            order: 2;
        }
        .title {
            font-weight: normal;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 16px;
        }
        p {
            font-size: 14px;
            color: #d3ddf2;
        }

        .image {
            order: 1;
            max-width: 70%;
            margin: 0 auto 40px;
            img {
                width: 100%;
            }
        }

        .phone {
            border-radius: 28px;
            width: 100%;
            padding: 10px;
            padding-right: 38px;
            display: flex;
            background: #6f83f7;
            height: 52px;
            align-items: center;
            margin-bottom: 50px;

            img {
                border: 2px solid #fff;
                border-radius: 50%;
                margin-right: 14px;
            }
            a {
                color: #ffffff;
                font-weight: 500;
                display: inline-block;
            }
        }
        .mobile {
            order: 3;
        }

        .greetings {
            margin-top: 80px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 40px;
            width: 180px;
        }
    }
}
