.side_bar {
    border-radius: 20px;
    width: 30%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        width: auto;
        margin: 0 20px;
    }
}

.current_info {
    padding: 40px;
    border-radius: 16px;
    background: #edf2fc;
}

.info_list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        color: #72778d;
    }

    span {
        color: #000;
        text-align: right;
    }
}
.phone {
    margin-top: 16px;
    width: 100%;
    padding: 10px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
        color: #1f285d;
        font-weight: 500;
        display: inline-block;
    }

    span {
        display: block;
        margin-right: 20px;
    }
}
