.radio_box {
    .input_holder {
        padding: 12px 64px 12px 16px;
        border: 1px solid #d5d5d5;
        border-radius: 4px;

        line-height: 150%;
        color: #111111;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        transition: 0.5s;
    }
    span {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #15c67b;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: #15c67b;
            border-radius: 50%;
            opacity: 0;
            transition: 0.5s;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }
    input:checked ~ .input_holder {
        background: #c5f1de;
        border: 1px solid #15c67b;

        span:after {
            opacity: 1;
        }
    }
}
