.date-input {
    border: 1px solid rgba(17, 17, 17, 0.2) !important;
    border-radius: 4px;
    height: 48px !important;
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
    }
    .MuiOutlinedInput-input {
        padding: 14.5px 14px;
    }
    .MuiOutlinedInput-notchedOutline {
        top: 0;
        border: none !important;
    }

    cursor: pointer;

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        display: none !important;
    }
    .MuiInputLabel-formControl {
        transform: translate(16px, 8px) scale(1);
        font-size: 14px;
        line-height: 150%;
        color: #111111;
    }

    .MuiInputLabel-shrink,
    .MuiFormLabel-root.Mui-focused {
        color: rgba(102, 102, 102, 0.8);
    }
    .MuiInput-formControl {
        margin-top: 0;
    }
    .MuiInputAdornment-positionStart {
        margin-right: -2px;
        margin-top: 5px;
    }
    input {
        margin-top: 5px;
        color: #111111;
        height: auto;
        padding: 6px 0 0px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #111111;
    }
    .MuiFormControl-root {
        display: flex;
    }
    .MuiFormHelperText-root {
        margin-top: -42px;
        text-align: right;
    }
}
