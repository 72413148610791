.message_wrapper {
    position: fixed;
    right: 0;
    background: #fff;
    padding: 5px 20px;
    min-height: 60px;
    align-items: center;
    width: 300px;
    top: 0px;
    border-bottom: 1px solid #e7e7e7;
    border-left: 10px solid #e7e7e7;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.03);
    z-index: 23;
    display: flex;
    transition: 0.5s;
    transform: translateX(100%);
    &.show {
        transform: translateX(0);
    }
    &.success {
        border-color: green;
        i {
            color: green;
        }
    }
    &.error {
        border-color: red;
        i {
            color: red;
        }
    }
}
