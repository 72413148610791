.progress-holder{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .bar{
        background:rgba(17, 17, 17, 0.15);
        border-radius: 2px;
        height: 4px;
        width: 32%;

        span{
            display: block;
            width: 0;
            background: #15C67B;
            transition: width 0.5s;
            height: 100%; 
        }
        &.show span{
            width: 100%;
        }
    }
} 
.requirements{
    padding: 0;
    margin: 8px 20px 0;

    li{
        font-size: 12px;
        line-height: 150%;  
        color: #666666;
    }
}