.stripe_wrapper {
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.stripe_wrapper .stripe_label {
    color: #1f285d;
    margin: 0 !important;
    display: block;
}
.stripe_wrapper .stripe_label span {
    font-size: 14px;
    margin-left: 25px !important;
}
.stripe_wrapper .StripeElement {
    padding: 14px 30px;
    height: 52px;
    border: 2px solid #c9d1e1;
    border-radius: 20px;
    margin-bottom: 20px;
}

.stripe_wrapper .card-error {
    color: red;
}

@media only screen and (max-width: 769px) {
    .stripe_wrapper {
        padding: 0;
    }
}
