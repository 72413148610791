.top-section-list-nav {
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    padding: 17px 20px 0 24px;
    margin-bottom: 24px;

    .back-arrow {
        margin-right: 20px;
        cursor: pointer;
        display: inline-block;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
    }

    .username {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    .image-holder {
        width: 32px;
        height: 32px;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
    }

    .name {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #111111;
    }

    .nav {
        li {
            .menu-item {
                font-size: 13px;
                line-height: 150%;
                margin-right: 32px;
                padding: 6px 0;
                cursor: pointer;
                border-bottom: 3px solid transparent;
                transition: 0.5s;
                text-transform: capitalize;

                &:hover,
                &.active {
                    border-bottom: 3px solid #15c67b;
                }
            }
        }
    }

    .action-buttons {
        .btn {
            margin-top: 0;
        }
        margin-top: 3px;
    }
}
@media only screen and (max-width: 769px) {
    .top-section-list-nav {
        height: auto;
        flex-wrap: wrap;
        padding: 20px 24px;
        justify-content: center;
        flex-direction: column;

        .action-buttons {
            flex-direction: column;
            display: flex;
        }
        button,
        a {
            margin-top: 10px !important;
            margin-left: 0;
        }
    }
}
