.main_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
}

.stripe_wrapper {
    margin: 0 -20px;
    display: flex;
    justify-content: center;
}

.payment_fields,
.car_wrapper {
    padding: 30px 50px;
    border-radius: 20px;
    width: 50%;
    margin: 0 10px;
}

.payment_fields {
    border: 1px solid #c9d1e1;

    button {
        width: 100%;
    }
}

.car_wrapper {
    padding: 0;
    padding-right: 50px;
    // background: #f6f8fd;
    // border: 1px solid #f6f8fd;
    .section_title {
        margin: 0;
    }
}

.section_title {
    font-weight: 600;
    margin-left: 25px;
    font-size: 16px;
    line-height: 24px;
    color: #1f285d;
    margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
    .main_title {
        display: none;
    }
    .payment_fields,
    .car_wrapper {
        padding: 0;
        width: 100%;
        margin: 0;
        background: transparent;
        border: none;
    }
    .stripe_wrapper {
        margin: 0;
        flex-direction: column;
    }
}
