.list_table_wrapper {
    position: relative;
    padding-bottom: 42px;

    .header {
        padding: 16px;
        display: flex;
        align-items: center;

        .result {
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: rgba(17, 17, 17, 0.5);
            margin-left: auto;
        }
    }

    .search_bar {
        background: #ffffff;
        border: 1px solid rgba(17, 17, 17, 0.15);
        box-sizing: border-box;
        border-radius: 100px;
        display: flex;
        overflow: hidden;
        padding: 0 12px;
        margin-right: 16px;
        width: 290px;

        img {
            margin-right: 9px;
        }

        input {
            border: none;
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 7px 0;

            &::placeholder {
                font-size: 13px;
                line-height: 150%;
                color: #111111;
                opacity: 0.3;
            }
        }
    }

    .filters {
        display: flex;

        .select {
            margin-right: 24px;
            background: rgba(161, 232, 202, 0.3);
            border-radius: 4px;

            & > div {
                padding: 0;
                background-color: transparent !important;
                padding: 4px 30px 4px 6px;
                color: #111111;
                font-size: 14px;
                line-height: 150%;
                position: relative;
            }
            &:before {
                display: none;
            }

            &:after {
                display: block;
                content: "";
                background: url("../../images/icons/filter-arrow-down-icon.svg");
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                right: 6px;
                top: 6px;
                position: absolute;
                transform: scaleX(1);
                border-bottom: none;
                left: auto;
                bottom: auto;
            }
            svg {
                display: none;
            }
        }
    }

    .footer {
        background: #fbfbfb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .result {
            font-size: 10px;
            line-height: 145%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #666666;
            font-weight: bold;
        }

        .pagination {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 145%;
            color: #666666;

            input {
                width: 24px;
                height: 24px;
                background: #ffffff;
                border: 1px solid rgba(17, 17, 17, 0.15);
                border-radius: 3px;
                margin-right: 8px;
                text-align: center;
            }

            .arrow {
                padding: 6px 12px;
                cursor: pointer;
                display: flex;
                align-items: center;

                &.disabled {
                    opacity: 0.3;
                    cursor: inherit;
                }
            }
        }

        .per_page {
            font-size: 12px;
            line-height: 145%;
            color: #666666;
            display: flex;
            align-items: center;
            p {
                margin-right: 8px;
            }
        }

        .select {
            background: #ffffff;

            & > svg {
                width: 20px;
                height: 20px;
                top: calc(50% - 11px);
            }

            & > div {
                padding: 0;
                background-color: transparent !important;
                font-size: 12px;
                line-height: 145%;
                color: #111111;
                padding: 4px 17px 4px 7px;
                border: 1px solid rgba(17, 17, 17, 0.15);
                border-radius: 3px;
                width: 22px;
            }

            &:after,
            &:before {
                display: none;
            }
        }
    }
}
.table_wrap {
    height: 70vh;
    width: 100%;
    overflow: auto;
    scrollbar-color: #888 #fff;
    scrollbar-width: none;
    // scrollbar-width: thin;
}
.list_table {
    min-width: 1530px;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;

    thead {
        position: sticky;
        top: 0;
        background: #fff;
    }
    // tbody {
    //     display: block;
    //     width: 100%;
    //     display: block;
    //     max-height: 300px;
    //     overflow-y: scroll;

    //     tr {
    //         display: table;
    //         width: 100%;
    //         table-layout: fixed;
    //     }
    // }
    // thead {
    //     display: table;
    //     width: 100%;
    //     table-layout: fixed;
    // }

    .sort_icon {
        background-image: url("../../images/icons/sort-list-icon.svg");
        background-position: center;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        background-repeat: no-repeat;
        transition: transform 0.5s;
    }

    .desc {
        .sort_icon {
            background-image: url("../../images/icons/pagination-next-icon.svg");
            transform: rotate(90deg);
        }
    }

    .asc {
        .sort_icon {
            background-image: url("../../images/icons/pagination-next-icon.svg");
            transform: rotate(-90deg);
        }
    }

    th {
        font-weight: 600;
        font-size: 10px;
        line-height: 150%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        position: relative;
        padding: 11px 26px 10px 16px;
        border-top: 1px solid rgba(17, 17, 17, 0.1);
        border-bottom: 1px solid rgba(17, 17, 17, 0.1);
        cursor: pointer;
        white-space: nowrap;

        &:last-of-type {
            text-align: right;
        }
    }
}

.MuiPopover-root {
    .filters_items {
        background: #f2fef9;

        .MuiListItem-root.Mui-selected,
        .MuiListItem-root.Mui-selected:hover {
            background-color: rgb(227, 248, 239);
        }
    }

    .per_page_items {
        border: 1px solid rgba(17, 17, 17, 0.15);
    }
}

@media only screen and (max-width: 769px) {
    .list_table_wrapper {
        .footer {
            .per_page p {
                display: none;
            }
        }
    }
}
