.home-popup-wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 50px;
    position: fixed;
    background: rgba(0, 0, 0, 0.2);

    .close {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;
    }

    .inner {
        padding: 50px 30px 0;
        max-width: 450px;
        width: 100%;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.03);
        position: relative;
    }

    .info-text {
        font-size: 14px;
        line-height: 150%;
        color: #666666;
        margin-bottom: 16px;
    }

    .title {
        font-family: Josefin Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        color: #000000;
        text-align: center;
        margin-bottom: 24px;
    }

    .mid-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #111111;
        margin-bottom: 24px;
    }

    .sub-title {
        margin-bottom: 8px;
        font-family: Inter;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #111111;
    }

    .link {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        margin-top: 30px;
        span {
            margin-left: 5px;
            color: #15c67b;
            cursor: pointer;
        }
    }

    .footer-text {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        line-height: 130%;
        color: #666666;
        a {
            color: #15c67b;
            font-weight: 600;
        }
    }
}
